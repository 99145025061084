const validateLockCode = value => {
  if (value.length < 4) {
    return 'Greater than 3'
  }
  if (value.length > 6) {
    return 'Less than 7'
  }
  return ''
}

export default validateLockCode
