import ListComponent from './propertiesList'
import ShowComponent from './propertiesShow'
import CreateComponent from './propertiesCreate'
import EditComponent from './propertiesEdit'
import SettingsComponent from './propertiesSetting'

export const PropertiesList = ListComponent
export const PropertiesShow = ShowComponent
export const PropertiesCreate = CreateComponent
export const PropertiesEdit = EditComponent
export const PropertiesSetting = SettingsComponent
