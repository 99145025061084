import React from 'react'
import ReactDOM from 'react-dom'
import ClearCache from 'react-clear-cache'
import './styles/index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(
  <ClearCache auto>
    {App}
</ClearCache>,
  document.getElementById('root')
)
registerServiceWorker()
