import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import http from '../../api/http'

class SendLockCodeButton extends Component {
  handleClick = () => {
    const { record } = this.props
    http.users
      .sendLockCode(record.user_id)
      .then(res =>
        // eslint-disable-next-line implicit-arrow-linebreak
        res.ok
          ? alert('Sending lock code via email and/or text...')
          : alert('error, check the console for more information')
      )
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        style={{ margin: '0 1em 1em 0' }}
        color="primary"
        variant="contained">
        Send Lock Code
      </Button>
    )
  }
}

SendLockCodeButton.propTypes = {
  record: PropTypes.shape({
    user_id: PropTypes.string
  })
}

export default SendLockCodeButton
