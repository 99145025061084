import React from 'react'
import { List, TextField, ReferenceField, Datagrid } from 'react-admin'

import EventFilter from './eventFilter'

const EventList = props => (
  <List {...props} perPage={20} filters={<EventFilter />}>
    <Datagrid>
      <ReferenceField
        label="Name"
        source="id"
        reference="events"
        linkType="show">
        <TextField source="info.message" />
      </ReferenceField>
      <ReferenceField
        label="Unit"
        source="unit_id"
        reference="units"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Property"
        source="property_id"
        reference="properties"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Company"
        source="company_id"
        reference="companies"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="User"
        source="user_id"
        reference="users"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default EventList
