/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import uuid from 'uuid/v4'
import Device from './device'
import {
  getDeviceProperties,
  mergeNewDeviceProperties,
  createDeviceCommand,
  getGatewayProperties
} from '../../utils/deviceUtils'
import { toDisplayName } from '../../utils/stringUtils'
import websocket from '../../api/websocket'
import http from '../../api/http'
import { initWebSocket } from '../../api/utils'

class Devices extends Component {
  state = {
    hubData: {},
    nonHubsData: []
  };

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.node
      }).isRequired
    }).isRequired,
    style: PropTypes.object
  };

  async componentWillMount() {
    const response = await http.devices.get({
      unit_id: this.props.match.params.id
    })
    if (response.length > 0) {
      const hubData = getGatewayProperties(response)
      this.setState({
        hubData,
        nonHubsData: response
          .filter(device => device.type !== 'gateway')
          .map(getDeviceProperties)
      })
      initWebSocket(hubData.identifier)
      websocket.joinChannel(
        `device:${hubData.identifier}`,
        response => response.body && this.updateDeviceData(response)
      )
    }
  }

  updateDeviceData({ body }) {
    this.setState({
      nonHubsData: this.state.nonHubsData.map(nonHubDeviceData => (nonHubDeviceData.identifier === body.identifier
          ? mergeNewDeviceProperties(nonHubDeviceData, body)
          : nonHubDeviceData))
    })
  }

  sendCommand = (type, deviceID, attributes) => {
    const command = createDeviceCommand(type, deviceID, attributes)
    websocket.sendCommand(command)
  };

  sendCommandRaw = command => {
    command.tag = command.tag || `supportUI-${uuid()}`
    websocket.sendCommand(command)
  };

  render() {
    return (
      <div style={this.props.style}>
        <List style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Device
            data={this.state.hubData}
            isHub
            title="Hub"
            sendCommand={this.sendCommand}
            sendCommandRaw={this.sendCommandRaw}
          />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly'
            }}
          >
            {this.state.nonHubsData.map(nonHubData => (
                <Device
                  key={nonHubData.identifier}
                  data={nonHubData}
                  sendCommandRaw={this.sendCommandRaw}
                  sendCommand={this.sendCommand}
                  title={toDisplayName(
                    nonHubData.type !== 'switch'
                      ? nonHubData.type
                      : nonHubData.subtype
                        ? 'Plug'
                        : 'Light'
                  )}
                />
              ))}
          </div>
        </List>
      </div>
    )
  }
}

export default Devices
