import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  List,
  ListItem
} from '@material-ui/core'
import Toast from 'react-material-toast'

import { capitalizeFirstLetter } from '../../utils/stringUtils'

import http from '../../api/http'

const roles = {
  company_admin: 'Company Admin',
  super_admin: 'Super Admin',
  property_admin: 'Property Admin',
  property_staff: 'Property Staff',
  setup: 'Setup',
  resident: 'Resident'
}

const toast = Toast.new({
  place: 'bottomRight',
  duration: 5
})

class ReassignUserButton extends Component {
  constructor(props) {
    super(props)
    const { record } = this.props

    this.state = {
      showModal: false,
      property: record.properties[0] ? record.properties[0].id : '',
      company: record.companies[0] ? record.companies[0].id : '',
      unit: record.units[0] ? record.units[0].id : '',
      role: record.role,
      id: record.id,
      multipleProperties: []
    }
  }

  isPropertyRole() {
    return this.state.role === 'property_staff' 
      || this.state.role === 'property_admin' 
      || this.state.role === 'setup'
  }

  // Handle input changes
  handleChange = name => event => this.setState({ [name]: event.target.value })

  handleChangeMultipleProperties = prop => {
    this.setState({
      multipleProperties: [...this.state.multipleProperties, prop],
      property: ''
    })
  }

  // eslint-disable-next-line consistent-return
  onSubmit = async () => {
    const payload = {
      units: [],
      properties: [],
      companies: [],
      role: this.state.role
    }

    switch (payload.role) {
      case 'resident':
        payload.units = [this.state.unit]
        break
      case 'company_admin':
        payload.companies = [this.state.company]
        break
      case 'property_admin':
      case 'property_staff':
      case 'setup':
        payload.properties = this.state.multipleProperties
        break
      default:
        return ''
    }

    const response = await http.users.reassign(this.state.id, payload)
    if (response.statusCode === 200) {
      this.setState({ showModal: false })
      toast.success(
        'User updated! Please allow a few minutes for changes to be active in all systems'
      )
    }
  }

  getInputId = () => {
    const { role } = this.state

    if (role !== 'super_admin') {
      return role === 'resident'
        ? 'unit'
        : role === 'company_admin'
        ? 'company'
        : 'property'
    }
    return ''
  }

  enterMultipleProperties = e => {
    if (e.keyCode === 13 && this.isPropertyRole()) {
      this.handleChangeMultipleProperties(e.target.value)
    }
  }

  getInputExtra() {
    return (
      this.state.role !== 'super_admin' && (
        <TextField
          required
          label={capitalizeFirstLetter(this.getInputId()) + ' ID'}
          fullWidth
          margin="normal"
          value={this.state[this.getInputId()]}
          onChange={this.handleChange(this.getInputId())}
          onKeyDown={e => this.enterMultipleProperties(e)}
        />
      )
    )
  }

  displaySelectedProperties = () => {
    if (this.isPropertyRole()) {
      const properties = this.state.multipleProperties

      return (
        properties.length > 0 && (
          <List>
            {properties.map(property => (
              <ListItem disableGutters key={property}>
                <p1 style={{ color: '#26A69A' }}>{property}</p1>
              </ListItem>
            ))}
          </List>
        )
      )
    }
  }

  clearProperties = () => {
    this.setState({ multipleProperties: []})
  }

  render() {
    return (
      <React.Fragment>
        <Button
          onClick={() => this.setState({ showModal: true })}
          style={{ margin: '0 1em 1em 0' }}
          color="primary"
          variant="contained">
          Reassign User
        </Button>

        <Dialog
          onClose={() => this.setState({ showModal: false })}
          aria-labelledby="reassign-user"
          open={this.state.showModal}
          fullWidth>
          <DialogTitle id="reasign-user">REASSIGN USER</DialogTitle>
          <DialogContent>
            <TextField
              required
              label="Role"
              select
              fullWidth
              value={this.state.role}
              onChange={this.handleChange('role')}>
              {Object.entries(roles).map(role => (
                <MenuItem value={role[0]} key={role[0]}>
                  {role[1]}
                </MenuItem>
              ))}
            </TextField>
            {this.getInputExtra()}
            {this.displaySelectedProperties()}
          </DialogContent>

          <DialogActions>
            {this.isPropertyRole() &&
              <Button onClick={this.clearProperties} color="primary">
                Clear Properties
              </Button>
            }
            <Button
              onClick={() => this.setState({ showModal: false })}
              color="primary">
              Close
            </Button>
            <Button onClick={this.onSubmit} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

ReassignUserButton.propTypes = {
  record: PropTypes.shape({
    properties: PropTypes.array,
    companies: PropTypes.array,
    units: PropTypes.array,
    role: PropTypes.string,
    id: PropTypes.number
  })
}

export default ReassignUserButton
