import React from 'react'
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  EditButton
} from 'react-admin'

const EmailTemplateList = props => (
  <List {...props} perPage={20}>
    <Datagrid>
      <ReferenceField
        label="name"
        source="name"
        reference="email_templates"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="subject" />
      <TextField source="from" />
      <EditButton />
    </Datagrid>
  </List>
)

export default EmailTemplateList
