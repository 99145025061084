

import { createMuiTheme } from '@material-ui/core/styles'
import { pink, blueGrey } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[400]
    },
    secondary: {
      main: '#26a69a'
    },
    error: pink
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiChip: {
      root: {
        maxWidth: '100%'
      },
      label: {
        maxWidth: '100%',
        overflow: 'hidden'
      }
    }
  }
})

export default theme
