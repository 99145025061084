// in src/authClient.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'
import http from './api/http'
import {
  hasValidRefreshToken,
  clearTokens,
  setTokens,
  getRefreshToken,
  getToken
} from './utils/authUtils'

const loginOrRefreshToken = (url, payload) => {
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Client-Type': 'support-app'
    })
  })
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      const body = response.json()
      http.setHeaders(body.id_token)
      return body
    })
    .then(response => {
      setTokens(response)
    })
}

export const tryRefreshToken = () => {
  if (hasValidRefreshToken()) {
    return loginOrRefreshToken(`${window.api_url}/login/refresh`, {
      refresh_token: getRefreshToken()
    })
  }
  clearTokens()
  return Promise.reject()
}

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    return loginOrRefreshToken(`${window.api_url}/login`, {
      user_or_email: username,
      password
    })
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    clearTokens()
    return Promise.resolve()
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      return tryRefreshToken()
    }
    return Promise.resolve()
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const token = getToken()
    if (token) {
      http.setHeaders(token)
      return Promise.resolve()
    }
    return tryRefreshToken()
  }
  return Promise.reject('Unknown method')
}
