/**
 * This component was created to be a wrapper for multiple fields/inputs inside any react-admin container component.
 * React-admin containers pass props to its children. Wrapping fields/inputs in a `div` means these props can't be assessed by the input/field.
 * This component is a generic container/wrapper which passess all its props to the children with the exception of the `parentProps` which is meant to be used for the container itself
 *
 * @module TransparentContainer
 *
 * @prop parentProps
 */

import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ children = [], parentProps = {}, ...childrenProps }) => {
  const newChildren = []
  const content = children instanceof Array ? children : [children]
  content.forEach(({ props, ...rest }) => {
    const child = { ...rest, props: { ...props, ...childrenProps } }
    newChildren.push(child)
  })

  const TagName = parentProps.tagName || 'div'
  delete parentProps.tagName

  // eslint-disable-next-line react/no-children-prop
  return <TagName {...parentProps} children={newChildren} />
}

Container.propTypes = {
  parentProps: PropTypes.shape({
    tagName: PropTypes.string
  }),
  children: PropTypes.array
}

export default Container
