/**
 * This component is a drop-in replacement for react-admin's SimpleForm.
 * SimpleForm sends all the data(record) belonging to the resource being edited to the API.
 * The Zego API may not accept the data when a 'restricted' field is present
 * This component is designed to send only the data belonging to the inputs specified as children
 *
 * @module SimpleFormCustom
 */

import React from 'react'
import PropTypes from 'prop-types'
import { SimpleForm } from 'react-admin'
import { get, set } from 'lodash'

const SimpleFormCustom = props => {
  const { record, children } = props
  const newRecord = { id: record.id }
  const content = children instanceof Array ? children : [children]
  content.forEach(({ props: { source } }) => source && set(newRecord, source, get(record, source)))

  return <SimpleForm {...props} record={newRecord} />
}

SimpleFormCustom.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number
  }),  
  children: PropTypes.array
}

export default SimpleFormCustom
