import React from 'react'
import { Filter, SelectInput, TextInput } from 'react-admin'
import { card_types } from './utils'

const CardFilter = props => (
    <Filter {...props}>
      <SelectInput
        optionText="name"
        choices={card_types}
        source="type"
        defaultValue={null}
        alwaysOn
      />
      <TextInput
        source="search"
        label="Search"
        defaultValue={null}
        alwaysOn
      />
    </Filter>
  )

export default CardFilter
