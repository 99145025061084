import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  Datagrid,
  EditButton
} from 'react-admin'

import CardFilter from './cardFilter'
import Button from '@material-ui/core/Button'
import http from '../api/http'

const ENTITY_COMPANY = 1
const ENTITY_PROPERTY = 2
const ENTITY_ALL = 3

const bulkAssignCards = async (selectedIds, entityType) => {

  let entityLabel
  let articleLabel
  let cardLabel = (selectedIds.length > 1) ? 'these cards' : 'this card'

  switch (entityType) {
    case ENTITY_COMPANY:
      entityLabel = 'Company'
      articleLabel = 'a Company'
      break
    case ENTITY_PROPERTY:
      entityLabel = 'Property'
      articleLabel = 'a Property'
      break
    case ENTITY_ALL:
      articleLabel = 'all companies'
      break
  }
  // texts for the user input prompt
  const input_add_remove_cards = {
    text: `Do you want to assign ${cardLabel} to ${articleLabel}, or unassign ${cardLabel} from ${articleLabel}?`,
    buttons: {
      assign: {
        text: 'Assign',
        value: true
      },
      unassign: {
        text: 'Unassign',
        value: false
      },
      cancel: 'Cancel'
    }
  }

  const input_entity_id = {
    text:
      `Enter the ID of the ${entityLabel}.`,
    content: 'input',
    inputPlaceholder: `${entityLabel} ID`,
    button: {
      text: 'Continue'
    },
    showCancelButton: true
  }


  let entity_id
  let assign_or_remove

  // TRY ask the user if they want to add or remove the card
  try {
    const input = await swal(input_add_remove_cards)
    assign_or_remove = input
    if(assign_or_remove === null) {
      return
    }
  } catch (e) {
    return swal(`Unknown error occured. Here's why: ${e.message}`)
  }

  if (entityType !== ENTITY_ALL) {
    // TRY ask the user for a property_id
    try {
      const input = await swal(input_entity_id)
      entity_id = parseInt(input, 10)
      if (!entity_id) return swal(`No ${entityLabel} ID entered.`)
    } catch (e) {
      return swal(`Unknown error occured. Here's why: ${e.message}`)
    }
  }

  // TRY to see if the property_id exists
  try {
    switch (entityType) {
      case ENTITY_PROPERTY:
        const pResponse = await http.properties.get({ id: entity_id })
        if (pResponse.body.id !== entity_id) {
          return swal(`Unable to find that ${entityLabel}. Here's why: ${e.message}`)
        }
        break
      case ENTITY_COMPANY:
        const cResponse = await http.companies.get({ id: entity_id })
        if (cResponse.body.id !== entity_id) {
          return swal(`Unable to find that ${entityLabel}. Here's why: ${e.message}`)
        }
        break
      default:
        break
    }
  } catch (e) {
    return swal(`Unable to find that ${entityLabel}. Here's why: ${e.message}`)
  }

  try {
    const type = assign_or_remove ? 'assign' : 'unassign'
    const response = await http.cards.bulkSet({
      cards: selectedIds,
      properties: (entityType === ENTITY_PROPERTY) ? [entity_id] : [],
      companies: (entityType === ENTITY_COMPANY) ? [entity_id] : [],
      global: (entityType === ENTITY_ALL),
      type
    })
    if (response.statusCode === 200) {
      return (selectedIds.length > 1) ? swal(`Cards ${type}ed!`) :  swal(`Card ${type}ed!`)
    }
  } catch (e) {
    return swal(`Failed. Here's why: ${e.message}`)
  }
}

const CardListActions = props => {
  const { selectedIds } = props
  return (
    <Fragment>
      <Button onClick={() => bulkAssignCards(selectedIds, ENTITY_PROPERTY)}>Add/Remove From Property</Button>
      <Button onClick={() => bulkAssignCards(selectedIds, ENTITY_COMPANY)}>Add/Remove From Company</Button>
      <Button onClick={() => bulkAssignCards(selectedIds, ENTITY_ALL)}>Add/Remove From All</Button>
    </Fragment>
  )
}

const CardList = props => (
  <List {...props} perPage={20} filters={<CardFilter />} bulkActionButtons={<CardListActions />}>
    <Datagrid>
      <ReferenceField
        label="Name"
        source="id"
        reference="cards"
        linkType="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="data.title" label="Title" sortable={false} />
      <TextField source="data.category" label="Category" sortable={false} />
      <TextField source="data.discount_description" label="Discount" sortable={false} />
      <DateField source="inserted_at" label="Created" />
      <TextField source="level" />
      <BooleanField source="global" />
      <EditButton />
    </Datagrid>
  </List>
)

CardListActions.propTypes = {
  selectedIds: PropTypes.any
}

export default CardList
