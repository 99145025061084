import React from 'react'
import {
  Create,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  BooleanInput,
  TextInput,
  required
} from 'react-admin'

const PropertiesCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Property Name" source="name" validate={required()} />
      <ReferenceArrayInput
        label="Company"
        source="company_id" 
        reference="companies"
        validate={required()}
        perPage={100}
      >
        <FormDataConsumer>
          { formData => {
              const sortedChoices = [...formData.choices].sort((companyA, companyB) => {
                const nameA = companyA.name.toUpperCase()
                const nameB = companyB.name.toUpperCase()

                if (nameA > nameB) {
                  return 1
                }

                if (nameA < nameB) {
                  return -1
                }

                return 0
              })

              return (
                <AutocompleteInput 
                  label="Company Name" 
                  optionText="name"
                  choices={sortedChoices} 
                  allowEmpty 
                  validate={required()}
                  source="company_id"
                />
              )
            }}
        </FormDataConsumer>
      </ReferenceArrayInput>
      <TextInput label="Property Address" source="address" />
      <BooleanInput source="enabled" defaultValue />
      <TextInput label="Work Order Reminder Email" source="work_order_email" />
      <TextInput label="Property Phone Number" source="phone_number" />
      <TextInput label="After Hours Number" source="emergency_phone_number" />
    </SimpleForm>
  </Create>
)

export default PropertiesCreate
