import React from 'react'
import { TextInput, required } from 'react-admin'
import SimpleForm from '../ra-custom/simpleForm'
import Edit from '../ra-custom/edit'

const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <TextInput source="phone_number" />
      <TextInput source="email" />
      <TextInput source="login" />
    </SimpleForm>
  </Edit>
)

export default UserEdit
