// in src/customRoutes.js
import React from 'react'
import { Route } from 'react-router-dom'
import { Firmware } from '../firmware'
import { BotChat } from '../bot_chat'
import { PropertiesSetting } from '../properties'

export default [
  <Route key="firmware" exact path="/firmware" component={Firmware} />,
  <Route
    key="settings"
    exact
    path="/properties/:property_id/settings"
    component={PropertiesSetting}
  />,
  <Route key="bot-chat" exact path="/bot-chat" component={BotChat} />
]
