/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ListItem } from '@material-ui/core'
import ActionDelete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { toDisplayName } from '../../utils/stringUtils'

class LockCodeListItem extends Component {
  state = {
    lockCodeSpinners: []
  }

  onClick = lockCode => {
    this.props.clickEvent(lockCode)
    this.setState({
      lockCodeSpinners: [...this.state.lockCodeSpinners, lockCode.lock_code]
    })
  }

  lockCodeDeleted = (nextProps, spinners) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    nextProps.dataValue.filter(code => spinners.indexOf(code.lock_code) > -1)
      .length === 0

  componentWillReceiveProps(nextProps) {
    const { lockCodeSpinners } = this.state
    if (this.lockCodeDeleted(nextProps, lockCodeSpinners)) {
      this.setState({
        lockCodeSpinners: lockCodeSpinners
      })
    }
  }

  render() {
    const { dataKey, dataValue } = this.props
    return (
      <div style={{ flex: '1 1' }}>
        <ListItem
          primaryText={toDisplayName(dataKey.toString())}
          disabled
          nestedItems={dataValue
            // eslint-disable-next-line react/prop-types
            .sort((a, b) => a.code_num - b.code_num)
            .map(lockCode => (
              <ListItem
                key={lockCode.code_num}
                primaryText={toDisplayName(lockCode.lock_code)}
                secondaryText={`Index ${lockCode.code_num}`}
                style={{ padding: '5px 16px 5px' }}
                disabled={true}
                rightIconButton={
                  lockCode.code_num < 50 ? (
                    this.state.lockCodeSpinners.indexOf(lockCode.lock_code) >
                    -1 ? (
                      <IconButton style={{ top: '-5px' }}>
                        <CircularProgress size={30} />
                      </IconButton>
                    ) : (
                      <IconButton
                        tooltip="SVG Icon"
                        style={{ top: '-5px' }}
                        onClick={() => this.onClick(lockCode)}
                        name={dataKey}>
                        <ActionDelete />
                      </IconButton>
                    )
                  ) : null
                }
              />
            ))}
        />
      </div>
    )
  }
}

LockCodeListItem.propTypes = {
  dataKey: PropTypes.number,
  dataValue: PropTypes.string,
  clickEvent: PropTypes.func
}

export default LockCodeListItem
