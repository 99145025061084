/**
 * A drop-in replacement for SimpleShowLayout that displays chidren in a grid
 *
 * @module GridShowLayout
 *
 * @example <GridLayout columns={3}>
 *
 * @param cols number of grid columns
 *
 * Chldren can be assigned a className colspan-{2-5} to determine the number of columns they will occupy
 */

import { SimpleShowLayout } from 'react-admin'
import styled from '@emotion/styled'

const cols = 3

const GridShowLayout = styled(SimpleShowLayout)(props => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: props.padding,

    '& > *': {
      width: `${100 / (props.cols || cols)}%`,
      overflow: 'hidden',
      paddingRight: '1em',
      boxSizing: 'border-box'
    },
    '& > * > *': {
      display: 'flex'
    },

    '& .colspan-2': {
      width: `${(100 / (props.cols || cols)) * 2}%`
    },
    '& .colspan-3': {
      width: `${(100 / (props.cols || cols)) * 3}%`
    },
    '& .colspan-4': {
      width: `${(100 / (props.cols || cols)) * 4}%`
    },
    '& .colspan-5': {
      width: `${(100 / (props.cols || cols)) * 5}%`
    }
  }))

export default GridShowLayout
