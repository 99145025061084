import React, { useState } from 'react'
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput
} from 'react-admin'
import { useHistory } from 'react-router'
import { TextField } from '@material-ui/core'

const UUIDInput = () => {
  const [id, setId] = useState('')
  const history = useHistory()

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      history.push({pathname: `/users/${id}/show` })
    }
  }

  return (
    <div style={{ marginBottom: 8 }}>
      <TextField label="UUID" onChange={e => setId(e.target.value)} onKeyDown={handleKeyDown} />
    </div>
  )
}

const roles = [
  { id: 'resident', name: 'Resident' },
  { id: 'designated_payer', name: 'Designated Payer' },
  { id: 'setup', name: 'Setup' },
  { id: 'property_staff', name: 'Property Staff' },
  { id: 'property_admin', name: 'Property Admin' },
  { id: 'company_admin', name: 'Company Admin' },
  { id: 'super_admin', name: 'Super Admin' }
]

const statusList = [
  { id: 'active', name: 'active' },
  { id: 'inactive', name: 'inactive' },
  { id: 'pending', name: 'pending' }
]

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="User" source="name" alwaysOn />
    <UUIDInput alwaysOn />
    <SelectInput source="account_status" alwaysOn choices={statusList} />
    <SelectInput source="roles" alwaysOn choices={roles} />

    <ReferenceInput
      label="Property Name"
      source="property_id"
      reference="properties"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      filterToQuery={searchText => ({ name: searchText })}
      alwaysOn>
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
  </Filter>
)

export default UserFilter
