/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import http from '../../api/http'

class PmsSyncButton extends Component {
  handleClick = () => {
    const {
      record: { id },
      type
    } = this.props

    http.properties
      .syncToPms({
        id,
        type
      })
      .then(res =>
        // eslint-disable-next-line implicit-arrow-linebreak
        res.body.msg === 'ok' ? alert('Sync initiated...') : console.log(res)
      )
      .catch(err => alert(err.message))
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        style={{ margin: '1em' }}
        color="primary"
        variant="contained">
        {`Sync ${this.props.type} With PMS`}
      </Button>
    )
  }
}

PmsSyncButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number
  }),
  type: PropTypes.string
}

export default PmsSyncButton
