import React from 'react'
import { Filter, TextInput } from 'react-admin'

const CompaniesFilter = props => (
  <Filter {...props}>
    <TextInput label="Company Name" source="name" alwaysOn />
  </Filter>
)

export default CompaniesFilter
