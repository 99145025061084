import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import http from '../../api/http'

class CreateGroupSelectorsButton extends Component {
  handleClick = () => {
    const { record } = this.props
    http.mix_tasks
      .createGroupSelectors(record.id)
      .then(res =>
        // eslint-disable-next-line implicit-arrow-linebreak
        res.ok
          ? alert('Task started to create group message selectors.')
          : alert('error, please check the console for more info')
      )
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        style={{ margin: '1em' }}
        color="primary"
        variant="contained">
        Create Group Selectors
      </Button>
    )
  }
}

CreateGroupSelectorsButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number
  })
}

export default CreateGroupSelectorsButton
