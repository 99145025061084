import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import { Title } from 'react-admin'
import http from '../api/http'

class BotChatComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message_content: '',
      identifier: '',
      bot_id: '',
      bot_token: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = name => event => {
      this.setState({
        [name]: event.target.value
      })
    };

  handleSubmit = async () => {
    try {
      const response = await http.bot.sendChatMessage({
        bot_id: this.state.bot_id,
        bot_token: this.state.bot_token,
        payload: {
          key_values: {
            message: this.state.message_content
          },
          profiles: [
            {
              identity: this.state.identifier
            }
          ]
        }
      })
      if (response.statusCode === 200) {
        alert('Message sent!')
      }
    } catch (e) {
      return alert(e)
    }
  };

  render() {
    return (
      <Card>
        <Title title="Bot Chat Interface" />
        <form
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '1em'
          }}
          noValidate
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField
            id="identifier"
            placeholder="User Identifier"
            margin="normal"
            required
            value={this.state.identifier}
            onChange={this.handleChange('identifier')}
          />
          <TextField
            id="bot_id"
            placeholder="Bot Id"
            margin="normal"
            required
            value={this.state.bot_id}
            onChange={this.handleChange('bot_id')}
          />
          <TextField
            id="bot_token"
            placeholder="Bot Token"
            margin="normal"
            required
            value={this.state.bot_token}
            onChange={this.handleChange('bot_token')}
          />
          <TextField
            id="message_content"
            placeholder="Type your message here!"
            required
            margin="normal"
            value={this.state.message_content}
            onChange={this.handleChange('message_content')}
            fullWidth
          />
          <Button
            type="submit"
            style={{ margin: '1em', width: '100px' }}
            color="primary"
            variant="contained"
          >
            Send
          </Button>
        </form>
      </Card>
    )
  }
}

export default BotChatComponent
