import React from 'react'
import PropTypes from 'prop-types'
import { SimpleShowLayout, TextField, RichTextField } from 'react-admin'
import Show from '../ra-custom/show'

const SMSTemplateTitle = ({ record }) => (
  <span>{record ? `${record.name}` : ''}</span>
)

const SMSTemplateShow = props => (
  <Show title={<SMSTemplateTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <RichTextField source="body" stripTags />
    </SimpleShowLayout>
  </Show>
)

SMSTemplateTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string
  })
}

export default SMSTemplateShow
