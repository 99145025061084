import React from 'react'
import PropTypes from 'prop-types'
import { TextInput, required } from 'react-admin'
import SimpleForm from '../ra-custom/simpleForm'
import Edit from '../ra-custom/edit'
import ToggleButton from '../common/toggleEnabledButton'

const Form = props => (
  <SimpleForm {...props}>
    <ToggleButton checked={props.record.enabled} />
    <TextInput source="name" validate={required()} />
    <TextInput source="email" />
    <TextInput source="phone" />
    <TextInput source="website" />
    <TextInput source="billing_address" />
    <TextInput source="shipping_address" />
  </SimpleForm>
)

const CompaniesEdit = props => (
  <Edit {...props}>
    <Form />
  </Edit>
)

Form.propTypes = {
  record: PropTypes.shape({
    enabled: PropTypes.bool
  })
}

export default CompaniesEdit
