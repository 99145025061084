import React from 'react'
import PropTypes from 'prop-types'
import { TextField, ReferenceField, DateField, BooleanField } from 'react-admin'
import Show from '../ra-custom/show'
import { Devices } from './devices'
import Users from '../users/partials/userList'
import EnsureLockCodesButton from './buttons/ensureLockCodesButton'
import GridShowLayout from '../ra-custom/gridShowLayout'

const styles = {
  marginTop: {
    marginTop: '1em'
  }
}

const UnitTitle = ({ record }) => <span>{record ? `${record.name}` : ''}</span>

const UnitsShow = props => (
  <div>
    <Show title={<UnitTitle />} {...props}>
      <GridShowLayout cols={3}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          label="Properties"
          source="property.id"
          reference="properties"
          linkType="show"
          style={styles.textFieldStyle}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <TextField source="schedule_state" />
        <TextField source="type" />
        <DateField source="inserted_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="address" />
        <BooleanField source="cell_notification" label="Smart Cellular Warning?" />
        <div
          className="colspan-3"
          style={{
            flex: '100%',
            justifyContent: 'flex-start',
            paddingTop: '10px'
          }}>
          <EnsureLockCodesButton {...props} />
        </div>
      </GridShowLayout>
    </Show>
    <Users {...props} roles="resident" />
    <Devices style={styles.marginTop} {...props} />
  </div>
)

UnitTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string
  })
}

export default UnitsShow
