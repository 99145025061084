import React from 'react'
import { SimpleForm, TextInput, required } from 'react-admin'
import Edit from '../ra-custom/edit'


const EmailTemplateEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="subject" validate={required()} />
        <TextInput source="from" validate={required()} />
        <TextInput source="body" multiline fullWidth validate={required()} />
      </SimpleForm>
    </Edit>
  )

export default EmailTemplateEdit
