import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import http from '../api/http'

export default class LuxerLocationIdSetting extends Component {
  state = { locationId: '', hasError: false }

  async componentDidMount() {
    const response = await http.properties.get({
      id: this.props.propertyId
    })
    const {
      integration_info: { luxer_location_id: locationId }
    } = response.body
    if (locationId) this.setState({ locationId })
  }

  async updateState() {
    const { locationId } = this.state
    if (locationId) {
      const update = {
        integration_info: { luxer_location_id: locationId }
      }
      await http.properties.update(this.props.propertyId, update)
    }
  }

  handleChange = event => {
    this.setState({ locationId: event.target.value })
  }

  handleBlur = () => {
    const { locationId } = this.state
    if (this.validate(locationId)) {
      this.updateState()
      this.setState({ hasError: false })
    } else {
      this.setState({ hasError: true })
    }
  }

  validate(value) {
    if (value === '') return true
    return /(^[0-9]+$)/.test(value)
  }

  render() {
    const { locationId, hasError } = this.state
    return (
      <TableRow>
        <TableCell>Luxer location ID</TableCell>
        <TableCell>
          <TextField
            id="setting-luxer-location-id"
            value={locationId}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            error={hasError}
            helperText={hasError ? 'Luxer location ID must be a number' : ''}
          />
        </TableCell>
      </TableRow>
    )
  }
}

LuxerLocationIdSetting.propTypes = {
  propertyId: PropTypes.string.isRequired
}
