import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dropzone from 'react-dropzone'
import http from '../api/http'

const accept = 'image/jpeg, image/png'
const maxSize = 10485760
const defaultStyle = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    textAlign: 'center',
    color: '#999'
  },
  acceptStyle: {
    background: '#20a592'
  },
  preview: {
    float: 'left'
  }
}

export default class CardUpload extends Component {
  state = {
    name: ''
  }

  onDrop = ([file]) => {
    this.setState({ file })
  }

  onUpload = async () => {
    const { id } = this.props
    const { file } = this.state
    const property = await http.cards.uploadImage({
      id,
      file
    })
    this.props.onUpdate(property)
  }

  getLabel = () => {
    const { file } = this.state
    if (typeof file === 'object') {
      return file.name
    }
    return 'Choose Image'
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'Baseline',
          paddingBottom: '7px'
        }}>
        <Dropzone
          onDrop={this.onDrop}
          accept={accept}
          maxSize={maxSize}
          minSize={100}
          multiple={false}
          style={defaultStyle.dropZone}
          acceptStyle={defaultStyle.acceptStyle}>
          {({ getRootProps, getInputProps }) => (
            <Button variant="contained" {...getRootProps()}>
              <input {...getInputProps()} />
              {this.getLabel()}
            </Button>
          )}
        </Dropzone>
        <div style={{ padding: '1em' }}>
          <Button onClick={this.onUpload} color="primary">
            Upload Image
          </Button>
        </div>
      </div>
    )
  }
}

CardUpload.propTypes = {
  id: PropTypes.number,
  onUpdate: PropTypes.func
}
