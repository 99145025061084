import React from 'react'
import PropTypes from 'prop-types'
import lodashGet from 'lodash.get'
import { Chip, Avatar } from '@material-ui/core'
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { isObject } from '../utils/objectUtils'

// eslint-disable-next-line no-unused-vars
const DocumentField = ({ source, record = {}, hideOnEmpty, ...props }) => {
  const result = lodashGet(record, source)
  if (!isObject(result) || Object.keys(result).length === 0) {
    if (hideOnEmpty) {
      return null
    }
    return <span>NA</span>
  }

  return (
    <React.Fragment>
      {Object.entries(result).map(([key, value], index) => (
        <Chip
          key={index}
          label={key || 'Unnamed'}
          component="a"
          href={value}
          target="_blank"
          color="secondary"
          style={{ margin: '0.5em' }}
          avatar={
            <Avatar>
              <FileIcon />
            </Avatar>
          }
          clickable
        />
      ))}
    </React.Fragment>
  )
}

DocumentField.defaultProps = {
  addLabel: true,
  hideOnEmpty: true
}

DocumentField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.string,
  hideOnEmpty: PropTypes.bool
}

export default DocumentField
