import React from 'react'
import {
  Create, SimpleForm, TextInput, required
} from 'react-admin'

const SMSTemplateCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="body" multiline fullWidth />
      </SimpleForm>
    </Create>
  )

export default SMSTemplateCreate
