export const LAST_STATE_PROPERTIES = {
  gateway: ['battery', 'cellular', 'fw_version', 'zwave_fw_version', 'device_timestamp', 'status'],
  door_lock: ['battery', 'device_timestamp', 'status', 'state'],
  thermostat: [
    'battery',
    'device_timestamp',
    'status',
    'state',
    'occ_cool_sp',
    'occ_heat_sp',
    'running_mode',
    'fan_mode',
    'system_mode'
  ],
  switch: ['state', 'status', 'device_timestamp']
}
