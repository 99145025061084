import React from 'react'
import {
  List,
  TextField,
  ReferenceField,
  BooleanField,
  Datagrid,
  EditButton
} from 'react-admin'
import PropertyFilter from './propertiesFilter'

const PropertiesList = props => (
    <List {...props} perPage={20} filters={<PropertyFilter />}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          label="Name"
          source="id"
          reference="properties"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Company"
          source="company_id"
          reference="companies"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="enabled" />
        <TextField source="udf.utility_account" label="Utility Account" />
        <TextField source="cname" />
        <TextField source="pms" />
        <EditButton />
      </Datagrid>
    </List>
  )

export default PropertiesList
