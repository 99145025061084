import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/moment'
import { addField, FieldTitle } from 'react-admin'
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

const makePicker = PickerComponent => {
  class Picker extends Component {
    onChange(date) {
      this.props.input.onChange(date)
      this.props.input.onBlur()
    }

    render() {
      const {
        input,
        options,
        label,
        source,
        resource,
        isRequired,
        className,
        meta,
        providerOptions,
        ...others
      } = this.props

      const { touched, error } = meta

      return (
        <div className="picker">
          <MuiPickersUtilsProvider {...providerOptions}>
            <PickerComponent
              {...options}
              label={
                <FieldTitle
                  label={label}
                  source={source}
                  resource={resource}
                  isRequired={isRequired}
                />
              }
              margin="normal"
              error={!!(touched && error)}
              helperText={touched && error}
              {...others}
              className={className}
              value={input.value ? input.value : null}
              onChange={date => this.onChange(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      )
    }
  }

  Picker.defaultProps = {
    input: {},
    isRequired: 'false',
    label: '',
    format: 'D/MM/YYYY',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
      utils: DateFnsUtils,
      locale: undefined
    }
  }

  Picker.propTypes = {
    input: PropTypes.shape({
      value: PropTypes.string,
      onBlur: PropTypes.func,
      onChange: PropTypes.func
    }),
    options: PropTypes.object,
    label: PropTypes.string,
    source: PropTypes.string,
    resource: PropTypes.string,
    isRequired: PropTypes.bool,
    className: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string
    }),
    providerOptions: PropTypes.object
  }
  return Picker
}

export const DateInput = addField(makePicker(DatePicker))
export const TimeInput = addField(makePicker(TimePicker))
export const DateTimeInput = addField(makePicker(DateTimePicker))
