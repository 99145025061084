import React from 'react'
import {
  List,
  TextField,
  ReferenceField,
  Datagrid,
  EditButton
} from 'react-admin'

const SettingList = props => (
    <List {...props} perPage={20}>
      <Datagrid>
        <ReferenceField
          label="Name"
          source="id"
          reference="settings"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  )

export default SettingList
