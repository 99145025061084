// in src/restClient
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils
} from 'react-admin'
import { stringify } from 'query-string'
import { tryRefreshToken } from './authClient'
import getAllPaginated from './utils/paginationFetchUtils'

const getIdFieldForResource = resource => (resource === 'email_templates' || resource === 'text_templates'
  ? 'name'
  : 'id')

const no_header_resources = [
  'email_templates',
  'settings',
  'text_templates'
]

const convertParamsToZego = (params, type, resource) => {
  if (type === UPDATE && resource === 'properties') {
    const {
      // eslint-disable-next-line no-unused-vars
      data: { documents, ...data },
      ...rem_params
    } = params
    return { data, ...rem_params }
  }
  return params
}

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const adminUIRequestToZegoRequest = (type, resource, params) => {
  let url = ''
  const options = {}
  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST: {
      const { id, target, pagination: { page, perPage }, sort: { field, order } } = params
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        page,
        page_size: perPage,
        order_by: `${field},${order.toLowerCase()}`,
        ...(target == null ? {} : { [target]: id })
      }
      url = `${window.api_url}/${resource}?${stringify(query)}`
      break
    }
    case GET_ONE:
      const query = {}

      if (resource === 'users') {
        query.with_pms = true
      }

      url = `${window.api_url}/${resource}/${params.id}?${stringify(query)}`
      break
    case GET_MANY: {
      url = params.ids.map(id => `${window.api_url}/${resource}/${id}`)
      break
    }
    case UPDATE:
      url = `${window.api_url}/${resource}/${params.id}`
      // todo refactor this if we start manipulating a bunch of stuff
      if (resource === 'units') {
        delete params.data.schedule_state
      }

      if (resource === 'properties' && params.data.launch_date && typeof params.data.launch_date !== 'string') {
        params.data.launch_date = params.data.launch_date
          .startOf('day')
          .utc(true)
      }

      options.method = 'PUT'
      options.body = JSON.stringify(params.data)
      break
    case CREATE:
      url = `${window.api_url}/${resource}`
      options.method = 'POST'
      options.body = JSON.stringify(params.data)
      break
    case DELETE:
      url = `${window.api_url}/${resource}/${params.id}`
      options.method = 'DELETE'
      break
    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }
  return { url, options }
}

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
const convertHTTPResponseToREST = (
  response,
  type,
  resource,
  params
) => {
  const id_field = getIdFieldForResource(resource)

  if (type === GET_MANY) {
    return {
      data: response.map(resp => ({ ...resp.json, id: resp.json[id_field] }))
    }
  }
  const { headers, json } = response
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      if (!headers.has('total') && !no_header_resources.includes(resource)) {
        throw new Error(
          'The total header is missing. We need the total header to build out pagination.'
        )
      }

      if (no_header_resources.includes(resource)) {
        return {
          data: json.map(resource => ({ ...resource, id: resource[id_field] })),
          total: json.length
        }
      }
      return {
        data: json.map(datum => ({ ...datum, id: datum[id_field] })),
        total: parseInt(headers.get('total'), 10)
      }
    case GET_ONE:
      const data = json instanceof Array ? json[0] : json
      return { data: { ...data, id: data[id_field] } }
    case CREATE:
      return { data: { ...params.data, id: json[id_field] } }
    case DELETE:
      return { data: { id: params.id } }
    default:
      return { data: { ...json, id: json[id_field] } }
  }
}

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */
export default (type, resource, params) => {
  params = convertParamsToZego(params, type, resource)
  return tryRefreshToken().then(() => {
    const { url, options } = adminUIRequestToZegoRequest(
      type,
      resource,
      params
    )
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
    }
    const token = localStorage.getItem('token')
    options.headers.set('Authorization', `Bearer ${token}`)
    options.headers.set('X-Client-Type', 'support-app')

    if (type === GET_MANY) {
      return Promise.all(
        url.map(single_url => fetchUtils.fetchJson(single_url, options))
      ).then(responses => convertHTTPResponseToREST(responses, type, resource, params))
    }
    return fetchUtils.fetchJson(url, options).then(initialResponse => {
      if (getAllPaginated[resource]) {
        return getAllPaginated[resource](initialResponse, url).then(response => convertHTTPResponseToREST(response, type, resource, params))
      }
      return convertHTTPResponseToREST(initialResponse, type, resource, params)
    })
  })
}
