import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import http from '../../api/http'

class SendActivationEmailButton extends Component {
  handleClick = () => {
    const { record } = this.props
    http.users
      .sendActivation(record.user_id)
      .then(alert(`Activation email sent to ${record.email}.`))
      .catch(err =>
        // eslint-disable-next-line implicit-arrow-linebreak
        alert(
          `Error: ${err.message}. Email not sent. Please check the console for more info.`
        )
      )
  }

  render() {
    const activated = this.props.record.account_activated
    return (
      <Button
        disabled={activated}
        onClick={this.handleClick}
        style={{ margin: '0 1em 1em 0' }}
        color="primary"
        variant="contained">
        Send Activation Email
      </Button>
    )
  }
}

SendActivationEmailButton.propTypes = {
  record: PropTypes.shape({
    email: PropTypes.string,
    account_activated: PropTypes.string,
    user_id: PropTypes.string
  })
}

export default SendActivationEmailButton
