import jwtDecode from 'jwt-decode'

export const hasValidRefreshToken = () => {
  const token = getRefreshToken()
  if (token == null) {
    return false
  }
  const decoded_token = jwtDecode(token)
  if (typeof decoded_token.exp !== 'number') {
    return false
  }
  const current_ts = Math.floor(new Date() / 1000)
  if (!decoded_token.jti || decoded_token.exp < current_ts) {
    return false
  }
  return true
}

export const clearTokens = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh_token')
}

export const setTokens = ({ id_token, refresh_token }) => {
  localStorage.setItem('token', id_token)
  localStorage.setItem('refresh_token', refresh_token)
}

export const getToken = () => localStorage.getItem('token')

export const getRefreshToken = () => localStorage.getItem('refresh_token')
