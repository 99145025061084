
/* eslint-disable implicit-arrow-linebreak */
import React from 'react'
import PropTypes from 'prop-types'
import {
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  ImageField,
  required,
  FormDataConsumer
} from 'react-admin'
import CardUpload from './cardUpload'
import Edit from '../ra-custom/edit'

import { card_types } from './utils'

const CardForm = props => (
  <React.Fragment>
    <SimpleForm {...props}>
      <TextInput source="name" validate={required()} />
      <ImageField source="data.image" hideOnEmpty />
      <CardUpload {...props.record} onUpdate={() => window.location.reload()} />
      <SelectInput optionText="name" choices={card_types} source="type" />
      <NumberInput source="level" step={1} />
      <BooleanInput source="global" />
      <TextInput source="data.title" label="Title" validate={required()} />
      <TextInput
        source="data.category"
        label="Category (eg. plug)"
        validate={required()}
      />
      <TextInput
        source="data.redirect_url"
        label="Link"
        validate={required()}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.type &&
          formData.type === 'device' && (
            <React.Fragment>
              <TextInput
                source="data.description"
                label="Service Manufacturer"
                multiline
                fullWidth
              />
              <TextInput
                source="data.manufacturer"
                label="Device Manufacturer"
              />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData.type &&
          formData.type === 'service' && (
            <React.Fragment>
              <TextInput
                source="data.discount_description"
                label="Discount Description"
                multiline
                fullWidth
              />
              <TextInput
                source="data.description"
                label="Service Description"
                multiline
                fullWidth
              />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </React.Fragment>
)

const CardEdit = props => (
  <Edit {...props}>
    <CardForm />
  </Edit>
)

CardForm.propTypes = {
  record: PropTypes.object
}

export default CardEdit
