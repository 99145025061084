import React from 'react'
import PropTypes from 'prop-types'
import { TextField, BooleanField } from 'react-admin'
import Show from '../ra-custom/show'
import GridShowLayout from '../ra-custom/gridShowLayout'
import SyncCompanyButton from './buttons/syncCompanyButton'

const CompaniesShow = props => (
  <Show {...props}>
    <GridShowLayout>
      <div className="colspan-3">
        <SyncCompanyButton id={props.id} />
      </div>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="billing_address" />
      <TextField source="shipping_address" />
      <TextField source="website" />
      <BooleanField source="enabled" />
      <TextField source="integration_info.pm_id" label="PM ID" />
    </GridShowLayout>
  </Show>
)

CompaniesShow.propTypes = {
  id: PropTypes.string
}

export default CompaniesShow
