import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  required
} from 'react-admin'

const SettingCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="description" multiline fullWidth validate={required()} />
        <TextInput source="default" validate={required()} />
      </SimpleForm>
    </Create>
  )

export default SettingCreate
