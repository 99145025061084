/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, FormControlLabel } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

class PlugCommands extends Component {
  state = {
    showSpinner: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.state !== this.props.data.state) {
      this.setState({
        showSpinner: false
      })
    }
  }

  onChange = () => {
    const { sendCommand, data } = this.props
    sendCommand('switch', data.identifier, {
      state: data.state === 'on' ? 'off' : 'on'
    })
    this.setState({
      showSpinner: true
    })
  };

  render() {
    const { data } = this.props
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: '0px 20px 0px 20px'
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <div style={{ padding: '20px 0px 10px 0px' }}>
            {this.state.showSpinner ? (
              <CircularProgress size={30} />
            ) : (
              <FormControlLabel
                label={data.state === 'on' ? 'On' : 'Off'}
                control={
                  <Switch
                    checked={data.state === 'on'}
                    onChange={this.onChange}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

PlugCommands.propTypes = {
  data: PropTypes.shape({
    state: PropTypes.string,
    identifier: PropTypes.string
  }),
  sendCommand: PropTypes.func
}

export default PlugCommands
