import React from 'react'
import PropTypes from 'prop-types'
import {
  Labeled,
  TextField,
  BooleanField,
  ImageField,
  ChipField,
  DateField,
  UrlField,
  ReferenceManyField,
  Pagination,
  Datagrid
} from 'react-admin'
import Show from '../ra-custom/show'
import GridShowLayout from '../ra-custom/gridShowLayout'

import JsonField from '../fields/json-field'
import SendResetEmailButton from './actionButtons/sendResetEmailButton'
import SendActivationEmailButton from './actionButtons/sendActivationEmailButton'
import ReassignUserButton from './actionButtons/reassignButton'
import SendLockCodeButton from './actionButtons/sendLockCodeButton'
import TransparentContainer from '../common/transparentContainer'
import { Link } from '../common/styles'
import http from '../api/http'
import CreateChatChannelButton from './actionButtons/createChatChannelButton'
import DeleteChatChannelButton from './actionButtons/deleteChatChannelButton'

const UserTitle = ({ record }) => <span>{record ? `${record.name}` : ''}</span>

const PropertiesTable = ({ record = {}, ...props }) => {
  const { role } = record

  if (['property_admin', 'property_staff'].includes(role)) {
    return (
      <Labeled label="Assigned Properties">
        <ReferenceManyField
          record={record}
          className="colspan-3"
          pagination={<Pagination rowsPerPageOptions={[]} />}
          perPage={100}
          reference="properties"
          target="user_id"
          {...props}>
            <Datagrid rowClick={id => `/properties/${id}/show`}>
              <TextField source="id" />
              <TextField source="name" />
              <BooleanField source="enabled" sortable={false} />
            </Datagrid>
        </ReferenceManyField>
      </Labeled>
    )
  }

  return null
}

PropertiesTable.propTypes = {
  record: PropTypes.shape({
    role: PropTypes.string
  })
}

PropertiesTable.defaultProps = {
  hideOnEmpty: true
}

const LinkLabel = props => {
  const { data, href } = props
  return data ? <Link href={href}>{data.name}</Link> : <span>NA</span>
}

LinkLabel.propTypes = {
  data: PropTypes.object,
  href: PropTypes.string
}

class UserShow extends React.Component {
  async componentDidMount() {
    const resources = await http.users.getResources(this.props.id)
    this.setState({ resources })
  }

  state = {
    resources: {
      companies: {},
      properties: {},
      units: {}
    }
  }

  render() {
    const {
      props,
      state: {
        resources: { companies = {}, properties = {}, units = {} }
      }
    } = this

    const propertyId = Object.keys(properties)[0]
    const companyId = Object.keys(companies)[0]
    const unitId = Object.keys(units)[0]

    return (
      <Show title={<UserTitle />} {...props}>
        <GridShowLayout>
          <ImageField source="picture" title="name" />
          {/* source="status" was added in order to render the label*/}
          <TransparentContainer
            addLabel={true}
            label="Actions"
            className="colspan-2"
            source="status">
            <SendResetEmailButton />
            <SendActivationEmailButton />
            <ReassignUserButton />
            <SendLockCodeButton />
            <CreateChatChannelButton />
            <DeleteChatChannelButton />
          </TransparentContainer>
          <LinkLabel data={units[unitId]} href={`#/units/${unitId}/show`} addLabel={true} label="Units"/>
          <LinkLabel data={companies[companyId]} href={`#/companies/${companyId}/show`} addLabel={true} label="Companies"/>
          <LinkLabel data={properties[propertyId]} href={`#/properties/${propertyId}/show`} addLabel={true} label="Properties"/>
          <TextField source="user_id" label="ID" />
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="email" />
          <TextField source="phone_number" />
          <BooleanField label="Account Activated?" source="account_activated" />
          <ChipField source="role" />
          <ChipField label="Account Status" source="account_status" />
          <ChipField
            label="Resident Status (based on MIn/Mout in PMS)"
            source="status"
          />{' '}
          <BooleanField
            label="Used Resident App?"
            source="has_used_resident_app"
            style={{ textAlign: 'left' }}
          />
          <TextField source="lock_code" />
          <DateField label="Last Login" source="last_login" />
          <TextField source="bio" />
          <UrlField
            label="Activation URL"
            source="activation_url"
            style={{ maxWidth: '100px', display: 'block' }}
          />
          <TextField label="PMS Name" source="pms_user.pms" />
          <JsonField
            source="pms_user"
            record={props.record}
            className="colspan-3"
          />
          <PropertiesTable
            label="Assigned Properties"
            className="colspan-3" />
        </GridShowLayout>
      </Show>
    )
  }
}

UserShow.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string
  }),
  id: PropTypes.string
}

UserTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string
  })
}

export default UserShow
