import React from 'react'
import { SimpleShowLayout, TextField } from 'react-admin'
import Show from '../ra-custom/show'

const SettingShow = props => (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
      </SimpleShowLayout>
    </Show>
  )

export default SettingShow
