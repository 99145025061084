import React from 'react'
import {
  TextField,
  ReferenceField,
  DateField
} from 'react-admin'
import Show from '../ra-custom/show'
import GridShowLayout from '../ra-custom/gridShowLayout'

const DeviceShow = props => (
    <Show {...props} hideEdit hideDelete>
      <GridShowLayout>
        <TextField source="id" />
        <TextField source="identifier" />
        <TextField label="Status" source="last_state.status" />
        <TextField source="make" />
        <TextField source="model" />
        <TextField label="ZWave Firmware Version" source="system_metadata.zwave_fw_version"/>
        <ReferenceField
          label="Unit"
          source="unit_id"
          reference="units"
          linkType="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <DateField source="inserted_at" showTime />
      </GridShowLayout>
    </Show>
  )

export default DeviceShow
