import React from 'react'
import { Filter, TextInput } from 'react-admin'

const DeviceFilter = props => (
    <Filter {...props}>
      <TextInput label="Search by Hub ID" source="identifier" alwaysOn />
      <TextInput label="Search by Visible Hub ID" source="visible_identifier" style={{ width: '190px' }} alwaysOn />
    </Filter>
  )

export default DeviceFilter
