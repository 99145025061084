import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pick, assign } from 'lodash'
import {
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import http from '../../api/http'
import { toDisplayName } from '../../utils/stringUtils'

const styles = {
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  }
}

const resourceIdMap = {
  properties: 'property_id',
  units: 'unit_id',
  companies: 'company_id'
}

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      usersData: []
    }
  }

  getUserProperties = data =>
    // eslint-disable-next-line implicit-arrow-linebreak
    assign(
      {},
      pick(data, [
        'user_id',
        'account_status',
        'role',
        'phone_number',
        'lock_code',
        'first_name',
        'last_name',
        'id',
        'last_login',
        'has_used_resident_app',
        'email',
        'account_activated'
      ])
    )

  async componentDidMount() {
    const resourceId = resourceIdMap[this.props.resource]
    const response = await http.users.get({
      [resourceId]: this.props.match.params.id,
      roles: this.props.roles
    })
    if (response.length > 0) {
      const usersData = response.map(this.getUserProperties)
      this.setState({
        isLoading: false,
        usersData
      })
    } else {
      this.setState({
        isLoading: false,
        usersData: []
      })
    }
  }

  render() {
    return (
      <Card style={{ marginTop: '1.5em' }}>
        <CardHeader title={this.props.title || 'Users'} />
        {this.state.isLoading ? (
          <div style={styles.loadingContainer}>
            <CircularProgress style={{ margin: '1em', alignSelf: 'center' }} />
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Activated</TableCell>
                <TableCell>Last Login</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.usersData.map(user => (
                <TableRow
                  key={user.user_id}
                  hover
                  component={Link}
                  to={`/users/${user.id}/show`}>
                  <TableCell component="th" scope="row">
                    {`${user.first_name || ''} ${user.last_name || ''}`}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone_number}</TableCell>
                  <TableCell>
                    <Chip label={user.account_status} />
                  </TableCell>
                  <TableCell>{user.account_activated ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    {user.last_login instanceof String
                      ? toDisplayName(user.lastLogin)
                      : 'Never'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    )
  }
}

Users.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.string,
  roles: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

export default Users
