import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import http from '../../api/http'

class EnsureLockCodesButton extends Component {
  handleClick = () => {
    const { id } = this.props.match.params

    http.devices
      .ensureLockCodes(id)
      .then(res =>
        // eslint-disable-next-line implicit-arrow-linebreak
        res.ok
          ? alert('starting ensure lock codes...')
          : alert('error, please check the console for more information')
      )
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  render() {
    return (
      <Button onClick={this.handleClick} color="primary" variant="contained">
        Ensure Lock Codes
      </Button>
    )
  }
}

EnsureLockCodesButton.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node
    }).isRequired
  }).isRequired
}

export default EnsureLockCodesButton
