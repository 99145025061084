import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  EditButton
} from 'react-admin'
import UnitsFilter from './unitsFilter'

const UnitsList = props => (
    <List
      {...props}
      perPage={20}
      filters={<UnitsFilter />}
    >
      <Datagrid>
        <ReferenceField
          label="name"
          source="id"
          reference="units"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />
        <ReferenceField
          label="Properties"
          source="property.id"
          reference="properties"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="schedule_state" />
        <TextField source="address" />
        <BooleanField label="Cell Notification?" source="cell_notification" />
        <EditButton />
      </Datagrid>
    </List>
  )

export default UnitsList
