import React from 'react'
import PropTypes from 'prop-types'
import { ShowButton, ListButton, DeleteButton, Edit } from 'react-admin'
import CardActions from '@material-ui/core/CardActions'

const cardActionStyle = {
  zIndex: 2,
  justifyContent: 'flex-end'
}

const Actions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
    <ShowButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    <DeleteButton basePath={basePath} record={data} resource={resource} />
  </CardActions>
)

const CustomEdit = props => <Edit actions={<Actions />} {...props} />

Actions.propTypes = {
  data: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string
}

export default CustomEdit
