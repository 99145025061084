import React from 'react'
import {
  SimpleForm, TextInput, required
} from 'react-admin'
import Edit from '../ra-custom/edit'

const SMSTemplateEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="body" multiline fullWidth validate={required()} />
      </SimpleForm>
    </Edit>
  )

export default SMSTemplateEdit
