import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Toast from 'react-material-toast'
import http from '../../api/http'

const buttonClass = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30%',
  padding: '5px'
}

const toast = Toast.new({
  place: 'topCenter',
  duration: 5
})

class HubCommands extends Component {
  onClick = key => {
    if (key.includes('firmware')) {
      const { id } = this.props.data
      http.devices.updateFirmware({
        id,
        type: key.includes('Core') ? 'core' : 'os'
      })
      return
    }
    const { sendCommand } = this.props
    sendCommand('gateway', this.props.data.identifier, key)
  };

  updateZwaveFirmware = async () => {
    const { data: { id }, title } = this.props
    await http.devices.updateFirmware({
      id,
      filepath: process.env.REACT_APP_ZWAVE_FIRMWARE_FILENAME
    })
    toast.success(`ZWave Firmware update for ${title} has been sent!!!`)
  }

  disassociateHub = id => {
    http.hub
      .unassign({ id })
      .then(alert('Hub Disassociated'))
      .catch(err => alert(err.message))
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          padding: '0px 20px 0px 20px'
        }}>
        <div style={buttonClass}>
          <Button
            onClick={() => this.onClick('add')}
            fullWidth
            color="primary"
            variant="contained">
            Inclusion Mode
          </Button>
        </div>
        <div style={buttonClass}>
          <Button
            onClick={() => this.onClick('rm')}
            fullWidth
            color="primary"
            variant="contained">
            Exclusion Mode
          </Button>
        </div>
        <div style={buttonClass}>
          <Button
            onClick={() => this.disassociateHub(this.props.data.id)}
            fullWidth
            color="primary"
            variant="contained">
            Disassociate Hub
          </Button>
        </div>
        <div style={buttonClass}>
          <Button
            onClick={() => this.onClick('rs')}
            fullWidth
            color="primary"
            variant="contained">
            Restart
          </Button>
        </div>
        <div style={buttonClass}>
          <Button
            onClick={() => this.onClick('firmwareOS')}
            fullWidth
            color="primary"
            variant="contained">
            Update Firmware OS
          </Button>
        </div>
        <div style={buttonClass}>
          <Button
            onClick={() => this.onClick('firmwareCore')}
            fullWidth
            color="primary"
            variant="contained">
            Update Firmware Core
          </Button>
        </div>
        <div style={buttonClass}>
          <Button
            onClick={this.updateZwaveFirmware}
            fullWidth
            color="primary"
            variant="contained">
            Update ZWave Firmware
          </Button>
        </div>
      </div>
    )
  }
}

HubCommands.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    identifier: PropTypes.string
  }),
  sendCommand: PropTypes.func,
  title: PropTypes.string
}

export default HubCommands
