import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

class HealButton extends Component {
  handleClick = () => {
    const { data, sendCommandRaw } = this.props

    sendCommandRaw({
      action: 'heal',
      target: data.identifier,
      name: 'controller'
    })
  };

  render() {
    return (
      <Button onClick={this.handleClick} color="primary" variant="outlined">
        Heal
      </Button>
    )
  }
}

HealButton.propTypes = {
  data: PropTypes.shape({
    identifier: PropTypes.string
  }),
  sendCommandRaw: PropTypes.func
}

export default HealButton
