import React from 'react'
import PropTypes from 'prop-types'
import JSONTree from 'react-json-tree'
import { get } from 'lodash'

const JsonField = ({ source, record, ...otherProps }) => {
  const json = get(record, source)
  return <JSONTree data={json} {...otherProps} />
}

JsonField.defaultProps = {
  addLabel: true,
  hideOnEmpty: true
}

JsonField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object
}

export default JsonField
