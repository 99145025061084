import React from 'react'
import PropTypes from 'prop-types'
import DoorLockCommands from './doorLockCommands'
import ThermostatCommands from './thermostatCommands'
import PlugCommands from './plugCommands'
import HubCommands from './hubCommands'

const Commands = props => {
  switch (props.data.type) {
    case 'door_lock':
      return <DoorLockCommands {...props} />
    case 'thermostat':
      return <ThermostatCommands {...props} />
    case 'switch':
      return <PlugCommands {...props} />
    case 'gateway':
      return <HubCommands {...props} />
    default:
      return null
  }
}

Commands.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string
  })
}

export default Commands
