/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  required,
  FormDataConsumer
} from 'react-admin'
import CardUpload from './cardUpload'

import { card_types } from './utils'

const CardCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <CardUpload {...props.record} onUpdate={() => window.location.reload()} />
      <SelectInput optionText="name" choices={card_types} source="type" />
      <NumberInput source="level" step={1} />
      <BooleanInput source="global" defaultValue />
      <TextInput source="data.title" label="Title" validate={required()} />
      <TextInput source="data.category" label="Category (eg. plug)" validate={required()}
      />
      <TextInput
        source="data.redirect_url"
        label="Link"
        validate={required()}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type &&
          formData.type === 'device' && (
            <div>
              <TextInput
                source="data.manufacturer"
                label="Device Manufacturer"
              />
              <TextInput
                source="data.description"
                label="Device Description"
                multiline
                fullWidth
              />
            </div>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData.type &&
          formData.type === 'service' && (
            <div>
              <TextInput
                source="data.discount_description"
                label="Discount Description"
                multiline
                fullWidth
              />
              <TextInput
                source="data.description"
                label="Service Description"
                multiline
                fullWidth
              />
            </div>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)

CardCreate.propTypes = {
  record: PropTypes.object
}

export default CardCreate
