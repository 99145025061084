import React from 'react'
import PropTypes from 'prop-types'
import { TextInput, required } from 'react-admin'
import { DateInput } from '../inputs/dates'
import PropertiesDocuments from './propertiesDocument'
import Edit from '../ra-custom/edit'
import SimpleForm from '../ra-custom/simpleForm'
import ToggleButton from '../common/toggleEnabledButton'
import TransparentContainer from '../common/transparentContainer'

//removing timestamp
const dateFormatter = date => date && typeof date === 'string' ? date.substring(0, 10) : date

const Form = props => (
  <SimpleForm redirect="show" {...props}>
    <ToggleButton checked={props.record.enabled} />
    <TextInput source="name" validate={required()} />
    <TextInput source="phone_number" />
    <TextInput source="address" />
    <TextInput source="emergency_phone_number" />
    <TextInput source="office_address_line_1" />
    <TextInput source="office_address_line_2" />
    <TextInput source="office_address_city" />
    <TextInput source="office_address_state" />
    <TextInput source="office_address_zip" />
    <TextInput source="office_hours" />
    <DateInput
      source="launch_date"
      label="Launch Date"
      options={{
        mode: 'landscape',
        minDate: new Date(),
        label:
          'Select the date that residents should start receiving emails/texts to activate their Zego account'
      }}
      format={dateFormatter}
    />
  </SimpleForm>
)

const PropertiesEdit = props => (
  <Edit {...props}>
    <TransparentContainer>
      <Form />
      <PropertiesDocuments />
    </TransparentContainer>
  </Edit>
)

Form.propTypes = {
  record: PropTypes.shape({
    enabled: PropTypes.bool
  })
}

PropertiesEdit.propTypes = {
  record: PropTypes.object
}

export default PropertiesEdit
