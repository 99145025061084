// in src/comments/ApproveButton.js
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import http from '../../api/http'

class SendResetEmailButton extends Component {
  handleClick = () => {
    const { record } = this.props
    http.users
      .resetPasswordEmail({
        user_or_email: record.login
      })
      .then(res =>
        // eslint-disable-next-line implicit-arrow-linebreak
        alert(`${res.body.details.msg} The email was sent to ${record.email}.`)
      )
      .catch(err =>
        // eslint-disable-next-line implicit-arrow-linebreak
        alert(
          `Error: ${err.message}. Please make sure this user has a valid email.`
        )
      )
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        style={{ margin: '0 1em 1em 0' }}
        color="primary"
        variant="contained">
        Send Reset Password Email
      </Button>
    )
  }
}

SendResetEmailButton.propTypes = {
  record: PropTypes.shape({
    login: PropTypes.string,
    email: PropTypes.string
  })
}

export default SendResetEmailButton
