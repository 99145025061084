import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MenuItemLink, getResources, Responsive } from 'react-admin'
import {
  SupervisorAccount as UsersIcon,
  Business as PropertiesIcon,
  MeetingRoom as UnitsIcon,
  BusinessCenter as BizIcon,
  Email as EmailIcon,
  Sms as SMSIcon,
  Settings as SettingsIcon,
  Android as BotIcon,
  Devices as DevicesIcon,
  Nfc as FirmwareIcon,
  CreditCard as CardsIcon,
  Event as EventsIcon
} from '@material-ui/icons'

const Menu = ({ onMenuClick, logout }) => (
  <div>
    <MenuItemLink
      to="/units"
      leftIcon={createElement(UnitsIcon)}
      primaryText="Units"
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/companies"
      leftIcon={createElement(BizIcon)}
      primaryText="Companies"
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/properties"
      leftIcon={createElement(PropertiesIcon)}
      primaryText="Properties"
      onClick={onMenuClick}
    />
    <MenuItemLink
      leftIcon={createElement(UsersIcon)}
      to="/users"
      primaryText="Users"
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/email_templates"
      primaryText="Email Templates"
      onClick={onMenuClick}
      leftIcon={createElement(EmailIcon)}
    />
    <MenuItemLink
      to="/text_templates"
      primaryText="SMS Templates"
      onClick={onMenuClick}
      leftIcon={createElement(SMSIcon)}
    />
    <MenuItemLink
      to="/events"
      leftIcon={createElement(EventsIcon)}
      primaryText="Events"
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/cards"
      leftIcon={createElement(CardsIcon)}
      primaryText="Cards"
      onClick={onMenuClick}
    />
    <MenuItemLink
      leftIcon={createElement(SettingsIcon)}
      to="/settings"
      primaryText="Settings"
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/firmware"
      leftIcon={createElement(FirmwareIcon)}
      primaryText="Firmware"
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/devices"
      leftIcon={createElement(DevicesIcon)}
      primaryText="Devices"
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/bot-chat"
      primaryText="Bot Messaging"
      onClick={onMenuClick}
      leftIcon={createElement(BotIcon)}
    />
    <Responsive xsmall={logout} medium={null} />
  </div>
)

const mapStateToProps = state => ({
  resources: getResources(state)
})

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object
}

export default connect(mapStateToProps)(Menu)
