import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  ReferenceField
} from 'react-admin'
import CompaniesFilter from './companiesFilter'

const CompaniesList = props => (
    <List {...props} filters={<CompaniesFilter />}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          label="Name"
          source="id"
          reference="companies"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="email" />
        <TextField source="phone" />
        <BooleanField source="enabled" />
        <EditButton />
      </Datagrid>
    </List>
  )

export default CompaniesList
