import http from '../api/http'

// urls are in this format {BASE_URL}/#/{pageName}/...
// grab the resource type from the url
const getPageName = url => url.substring(url.indexOf('#'), url.length).split('/')[1]

const hasMorePages = response => {
  const totalPages = response.headers.get('total-pages')
  if (!totalPages) {
    return false
  }
  if (totalPages === 1) {
    return false
  }
  return true
}

const shouldGetAll = (pageNames, initialResponse) => {
  const pageName = getPageName(window.location.href)

  return !(!pageNames.includes(pageName) || !hasMorePages(initialResponse))
}

export const getAllResources = pages => async (initialResponse, url) => {
    if (shouldGetAll(pages, initialResponse)) {
      const newResponse = await http.getAllPaginatedResourcesFromResponse(
        initialResponse,
        url
      )
      return newResponse
    }
    return initialResponse
  }

export default {}
