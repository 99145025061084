import {
  chain, pick, assign, reject
} from 'lodash'
import { compose } from 'lodash/fp'
import { LAST_STATE_PROPERTIES } from '../constants/deviceConstants'

const getGateway = devices => devices.filter(isGateway)[0]

export const lockCodeExists = (lockCodes, code) => lockCodes
  && lockCodes.find(lockCode => lockCode.lock_code === code) !== undefined

export const isIndexAvailable = data => data.lock_codes.filter(lockCode => [1, 2, 3].includes(lockCode.code_num))
    .length < 3

export const getFirstAvailableIndex = lockCodes => [1, 2, 3].find((value, index) => lockCodes[index].code_num !== value)

export const getDeviceProperties = data => assign(
    {},
    pick(data, ['identifier', 'type', 'id', 'make', 'model']),
    chain(data)
      .get(['last_state', 'data'])
      .pick(LAST_STATE_PROPERTIES[data.type])
      .value(),
    chain(data)
      .get(['udf'])
      .pick(['lock_codes', 'subtype'])
      .value()
  )

export const getGatewayProperties = compose(
  getDeviceProperties,
  getGateway
)

export const mergeNewDeviceProperties = (existingData, newData) => assign(
    {},
    existingData,
    pick(newData, LAST_STATE_PROPERTIES[newData.device_type]),
    lockCodeEvents[newData.event] && {
      lock_codes: lockCodeEvents[newData.event](existingData, newData)
    }
  )

export const createDeviceCommand = (type, device_address, attributes = {}) => (type !== 'gateway'
    ? {
      name: 'controller',
      action: type,
      target: device_address,
      attributes
    }
    : {
      type,
      command: {
        device_address,
        action: attributes
      }
    })

const lockCodeEvents = {
  new_user_code(existingData, newData) {
    const lock_code = newData[`user_code_${newData.user_code}`]
    const { user_code, device_timestamp, expiration } = newData
    return [
      ...reject(
        existingData.lock_codes,
        lockCode => lockCode.code_num === user_code
      ),
      {
        code_num: user_code,
        created_at: device_timestamp,
        sweep_timeout: null,
        lock_code,
        expiration
      }
    ]
  },
  del_user_code(existingData, newData) {
    return existingData.lock_codes.reduce((newArray, value) => {
      if (value.code_num !== newData.user_code) {
        newArray.push(value)
      }
      return newArray
    }, [])
  }
}

const isGateway = device => device.type === 'gateway'
