/* eslint-disable no-unused-vars */
import { Socket } from 'phoenix-socket'

let channel
let socket

const initialize = token => {
  const socketURL = process.env.REACT_APP_WEB_SOCKET_URL || 'wss://api-stage.zego.io/device'
  socket = new Socket(socketURL, {
    params: { token }
  })
  socket.connect()
}

const joinChannel = (channelName, callback) => {
  channel = socket.channel(channelName)

  channel.on('update', payload => {
    typeof callback === 'function' && callback(payload)
  })
  channel
    .join()
    .receive('ok', response => {
    })
    .receive('error', response => {
    })
}

const sendCommand = (command, callback) => {
  if (!channel) {
    return
  }
  channel.push('command', command)
  typeof callback === 'function' && callback()
}

export default {
  initialize,
  joinChannel,
  sendCommand
}
