import React from 'react'
import Card from '@material-ui/core/Card'
import { Title } from 'react-admin'
import { CardHeader, CardContent } from '@material-ui/core'

const cardStyle = {
  margin: '2em'
}

const Dashboard = () => (
  <Card style={cardStyle}>
    <Title title="Zego Support UI" />
    <CardHeader title="Welcome to the Support Ui for the Zego Platform" />
    <CardContent>This is the support ui for the Zego Platform!</CardContent>
    <CardContent>Eventually analytics and what not will go here!</CardContent>
  </Card>
)

export default Dashboard
