import React from 'react'
import {
  Create, SimpleForm, TextInput, required
} from 'react-admin'
import RichText from '../rich_text/richText'

const EmailTemplateCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='name' validate={required()}/>
        <TextInput source='subject' validate={required()}/>
        <TextInput source='from'/>
        <RichText source='body' />
      </SimpleForm>
    </Create>
  )

export default EmailTemplateCreate
