import React, { useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw } from 'draft-js'
import { useField, Field } from 'react-final-form'
import {Labeled } from 'react-admin'

type Props = {
  initialValue: ?String,
  source: String
}

const RichText = ({ initialValue, source } : Props) => {
  const [value, setValue] = useState(initialValue)
  const { input: { onChange } } = useField(source)
  const onRichContentChanged = editorState => {
    const currentContent = editorState.getCurrentContent()
    const rawValue = currentContent
      ? draftToHtml(convertToRaw(currentContent))
      : ''
    setValue(rawValue)
    onChange(rawValue)
  }

  const renderRichEditor = () => <Editor onEditorStateChange={onRichContentChanged} />
  return <>
      <Labeled label={source}>
        <Field name={source} render={renderRichEditor} value={value} source={source}/>
      </Labeled>
  </>
}

export default RichText
