import React from 'react'
import {
  Filter, TextInput, ReferenceInput, AutocompleteInput
} from 'react-admin'

const PropertyFilter = props => (
    <Filter {...props}>
      <ReferenceInput
        label="Company Name"
        source="company_id"
        reference="companies"
        sort={{ field: 'name', order: 'DESC' }}
        perPage={100}
        filterToQuery={searchText => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput label="Properties" source="name" alwaysOn />
    </Filter>
  )

export default PropertyFilter
