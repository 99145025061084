import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import { toDisplayName } from '../../utils/stringUtils'
import Commands from './commands'
import LockCodeListItem from './lockCodeListItem'
import HealButton from './healButton'

const deviceStyle = {
  display: 'flex',
  flexDirection: 'column'
}

const hubStyle = {
  display: 'flex',
  justifyContent: 'space-evenly'
}

const iframeStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center'
}

class Device extends Component {
  onClick = key => {
    const { sendCommand } = this.props
    sendCommand('door_lock', this.props.data.identifier, {
      [`user_code_${key.code_num}`]: -1
    })
  };

  render() {
    const { data = [], title, isHub = false } = this.props
    return (
      <Card
        style={
          isHub
            ? { width: '100%' }
            : { marginTop: '1em', minWidth: '24%', maxWidth: '33%' }
        }>
        <CardHeader
          title={title}
          action={isHub && <HealButton {...this.props} />}
        />
        <List style={isHub ? hubStyle : deviceStyle}>
          {Object.entries(data).map(([dataKey, dataValue]) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            dataKey === 'lock_codes' ? (
              <LockCodeListItem
                key={dataKey}
                dataKey={dataKey}
                dataValue={dataValue}
                clickEvent={this.onClick}
              />
            ) : (
              <div key={dataKey}>
                <ListItem disabled style={{ padding: '5px 16px 5px' }}>
                  <ListItemText
                    primary={toDisplayName(dataKey.toString())}
                    secondary={
                      dataValue === null
                        ? ''
                        : toDisplayName(dataValue.toString())
                    }
                  />
                </ListItem>
              </div>
            )
          )}
          {!isHub && <Commands {...this.props} />}
        </List>
        {isHub && (
          <div style={iframeStyle}>
            <iframe
              src={`${process.env.REACT_APP_PAPERTRAIL_LOG_URL}?q=${data.identifier}`}
              title="abc"
              width="95%"
              height="100%"
              style={{ minHeight: '750px', border: 'none' }}
            />
            <div style={{ width: '100%', padding: '25px' }}>
              <Commands {...this.props} />
            </div>
          </div>
        )}
      </Card>
    )
  }
}

Device.propTypes = {
  data: PropTypes.shape({
    identifier: PropTypes.string
  }),
  sendCommand: PropTypes.func,
  isHub: PropTypes.bool,
  title: PropTypes.string
}

export default Device
