import React from 'react'
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  RichTextField,
  EditButton
} from 'react-admin'

const SMSTemplateList = props => (
    <List {...props} perPage={20}>
      <Datagrid>
        <ReferenceField
          label="name"
          source="name"
          reference="text_templates"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <RichTextField source="body" stripTags />
        <EditButton />
      </Datagrid>
    </List>
  )

export default SMSTemplateList
