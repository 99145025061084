/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Authenticated, Title } from 'react-admin'
import Card from '@material-ui/core/Card'

import List from '@material-ui/core/List'
import http from '../api/http'
import {
  populateInitialFields,
  validateMacAddress,
  pollPapertrailLogs
} from '../utils/firmwareUtils'
import { convertHubIDToScoutHubID } from '../utils/stringUtils'
import Toast from 'react-material-toast'

// Display on success
const toast = Toast.new({
  place: 'bottomRight',
  duration: 5
})

const NUMBER_OF_FIELDS = 15

class Firmware extends Component {
  state = {
    data: [],
    retryButton: false
  }

  componentWillMount() {
    this.setState({ data: populateInitialFields(NUMBER_OF_FIELDS) })
  }

  componentWillUnmount() {
    this.stopPolling()
  }

  onChange = index => event => {
    const { value } = event.target
    const { data } = this.state
    const newData = [...data]

    // Return if invalid char is entered or value > 12
    if (value.match(/[^0-9a-f]/) || value.length > 12) {
      return
    }

    newData[index].value = value
    this.setState({
      data: newData
    })
  }

  sendFirmwareUpdates = (nonDefaultMacInfos, index) => {
    if (nonDefaultMacInfos[index]) {
      setTimeout(async () => {
        const res = await http.devices.updateScoutFirmware({
          identifier: nonDefaultMacInfos[index].value
        })
        if (true === res.success) {
          toast.success(
            `${nonDefaultMacInfos[index].value} update request sent. `
          )
          this.setState({
            data: this.state.data.map(hubInfo => {
              if (hubInfo.value === nonDefaultMacInfos[index].value) {
                hubInfo.updating = false
              }
              return hubInfo
            })
          })
        }
        this.sendFirmwareUpdates(nonDefaultMacInfos, index + 1)
      }, 1000)
    }
  }

  setLoadingTrue = nonDefaultMacInfos => {
    this.setState({
      data: nonDefaultMacInfos.map(macInfo => {
        macInfo.updating = true
        return macInfo
      })
    })
  }

  onUpdateAll = async () => {
    const { data } = this.state
    const nonDefaultMacInfos = data.filter(macInfo => validateMacAddress(macInfo.value))
    this.setLoadingTrue(nonDefaultMacInfos)
    this.sendFirmwareUpdates(nonDefaultMacInfos, 0)
    this.pollPapertrailLogs = pollPapertrailLogs(this.checkUpdateFirwareVersion)
    setTimeout(() => {
      this.stopPolling()
      this.setState({
        retryButton: true,
        data: this.state.data
          .filter(hubInfo => hubInfo.updating === true)
          .map(hubInfo => {
            hubInfo.updating = false
            return hubInfo
          })
      })
    }, 60000)
  }

  stopPolling = () => clearInterval(this.pollPapertrailLogs)

  checkUpdateFirwareVersion = hubsFromLogs => {
    const { data } = this.state
    const newData = data.reduce((newData, macInfo) => {
      const newMacInfo = Object.assign({}, macInfo)
      const firmwareVersion =
        hubsFromLogs[convertHubIDToScoutHubID(macInfo.value)]
      if (firmwareVersion === process.env.REACT_APP_FIRMWARE_VERSION) {
        newMacInfo.updating = false
        newMacInfo.firmwareVersion = firmwareVersion
      }
      return [...newData, newMacInfo]
    }, [])
    this.setState({
      data: newData
    })
    const allUpdated =
      newData.filter(data => data.updating === true).length === 0
    allUpdated && this.stopPolling()
  }

  reset = () => this.setState({ data: populateInitialFields(NUMBER_OF_FIELDS) })

  render() {
    const { data } = this.state
    return (
      <Authenticated location={this.props.location}>
        <Card>
          <Title title="Firmware" />
          <List>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              {data.map((field, index) => (
                <div key={index}>
                  <TextField
                    style={{ margin: '1em' }}
                    label="Mac Address"
                    onChange={this.onChange(index)}
                    value={field.value}
                  />
                  {field.updating ? (
                    <CircularProgress size={20} />
                  ) : field.firmwareVersion > 0 ? (
                    field.firmwareVersion
                  ) : null}
                </div>
              ))}
            </div>
          </List>
          <div
            style={{
              padding: '1em',
              display: 'flex'
            }}>
            <div style={{ padding: '1em' }}>
              <Button
                onClick={this.onUpdateAll}
                color="primary"
                variant="contained">
                Update All
              </Button>
            </div>
            <div style={{ padding: '1em' }}>
              <Button
                onClick={this.reset}
                disabled={data.find(field => field.isUpdating === true)}>
                Reset
              </Button>
            </div>
            {this.state.retryButton && (
              <div style={{ padding: '1em' }}>
                <Button
                  onClick={this.onUpdateAll}
                  color="primary"
                  variant="contained">
                  Retry
                </Button>
              </div>
            )}
          </div>
        </Card>
      </Authenticated>
    )
  }
}

Firmware.propTypes = {
  location: PropTypes.object
}

export default Firmware
