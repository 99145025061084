import request from 'superagent'

export const populateInitialFields = numberOfFields => {
  const fields = []
  for (let index = 0; index < numberOfFields; index++) {
    fields.push(firmwareField())
  }
  return fields
}

export const validateMacAddress = macAddress => macAddress.length === 12 && macAddress.match(/[0-9a-f]{12}/)

export const updateAllErrors = data => data.reduce((invalidList, macInfo) => {
    const newMacInfo = Object.assign({}, macInfo)
    if (validateMacAddress(macInfo.value)) {
      invalidList.push(newMacInfo)
      return invalidList
    }
    newMacInfo.error = '12 Characters'
    invalidList.push(newMacInfo)
    return invalidList
  }, [])

export const pollPapertrailLogs = predicate => setInterval(() => queryPaperTrail(predicate), 5000)

const queryPaperTrail = async predicate => {
  const req = await request
    .get('https://papertrailapp.com/api/v1/events/search.json')
    .query({
      q: 'fw_version status update',
      system_id: process.env.REACT_APP_PAPERTRAIL_SYSTEM_ID,
      min_time: Math.round(new Date().getTime() / 1000) - 120
    })
    .set('X-Papertrail-Token', process.env.REACT_APP_PAPERTRAIL_TOKEN)

  // eslint-disable-next-line no-unused-expressions
  typeof predicate === 'function' && predicate(parsePaperTrailLogs(req.body))
}

const parsePaperTrailLogs = ({ events }) => events.reduce((hubs, { message }) => {
    const { fw_version, hub_id } = JSON.parse(
      message
        .split(': %')[1]
        .replace(/%/g, '')
        .replace(/=>/g, ':')
    )
    return {
      ...hubs,
      ...{ [hub_id]: fw_version }
    }
  }, {})

const firmwareField = () => ({
    updating: false,
    value: '',
    error: '',
    firmwareVersion: 0
  })
