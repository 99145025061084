import React from 'react'
import PropTypes from 'prop-types'
import { TextField, ReferenceField } from 'react-admin'
import Show from '../ra-custom/show'
import GridShowLayout from '../ra-custom/gridShowLayout'
import ObjectField from '../fields/json-field'

const EventTitle = ({ record }) => (
  <span>{record && record.info ? record.info.message : 'Event'}</span>
)

const EventShow = props => (
  <Show title={<EventTitle />} {...props} hideEdit hideDelete>
    <GridShowLayout cols={3}>
      <TextField source="id" />
      <ReferenceField
        label="Unit"
        source="unit_id"
        reference="units"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Property"
        source="property_id"
        reference="properties"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Company"
        source="company_id"
        reference="companies"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="User"
        source="user_id"
        reference="users"
        linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <div title="space filler" />
      <ObjectField source="info" label="Event Information" />
      <ObjectField
        source="meta_data"
        label="Event Metadata"
        className="colspan-2"
      />
    </GridShowLayout>
  </Show>
)

EventTitle.propTypes = {
  record: PropTypes.shape({
    info: PropTypes.string,
    message: PropTypes.string
  })
}

export default EventShow
