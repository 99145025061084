import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  TextField,
  Avatar,
  MenuItem,
  Button,
  Chip,
  Tooltip
} from '@material-ui/core'
import { HttpAgent } from '../api/http'

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  avatar: {
    width: 100,
    height: 100
  },
  logoArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '20%'
  },
  input: {
    flex: '1 1 8%',
    marginLeft: '1em'
  },
  hidden: {
    display: 'none'
  },
  center: {
    alignSelf: 'center',
    margin: 'auto'
  }
}

class Customization extends Component {
  state = {}

  async componentDidMount() {
    const { body: options } = await HttpAgent.get(
      `${window.api_url}/properties/${this.props.id}/customization_options`
    )
    this.setState({ options, initialOptions: options })
  }

  handleChange = key => event =>
    // eslint-disable-next-line implicit-arrow-linebreak
    this.setState({
      options: { ...this.state.options, [key]: event.target.value }
    })

  async handleSubmit(event) {
    event.preventDefault()
    const fd = new FormData()
    Object.entries(this.state.options).forEach(([key, value]) => fd.append(key, value))

    this.setState({ updating: true })
    try {
      const { body: options } = await HttpAgent.put(
        `${window.api_url}/properties/${this.props.id}/customization_options`
      ).send(fd)

      this.setState({ options, initialOptions: options, updating: false })
    } catch (err) {
      this.setState({ updating: false })
    }
  }

  handleFileChange(event) {
    const [logo_file] = event.target.files
    this.setState({ options: { ...this.state.options, logo_file } })
  }

  handleFileRemove() {
    const options = { ...this.state.options }
    delete options.logo_file
    document.getElementById('logo-input').value = ''
    this.setState({ options })
  }

  reset() {
    document.getElementById('logo-input').value = ''
    this.setState({ options: this.state.initialOptions })
  }

  render() {
    const { options, initialOptions, updating } = this.state
    const defaultColor = ''
    return (
      <Card style={{ marginTop: '1em' }}>
        <CardHeader
          title="Customization Options"
          action={
            <CardActions>
              <Button variant="outlined" onClick={this.reset.bind(this)}>
                Reset
              </Button>
              {updating ? (
                <CircularProgress style={styles.center} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit.bind(this)}>
                  Save
                </Button>
              )}
            </CardActions>
          }
        />
        <CardContent style={styles.container}>
          {options ? (
            <React.Fragment>
              <div style={styles.logoArea}>
                <Avatar
                  alt="Logo"
                  style={styles.avatar}
                  src={initialOptions.logo_file_url}
                />
                <Tooltip
                  title="Recommended Aspect Ration - 32 : 9"
                  placement="right">
                  <Button component="label">
                    Change
                    <input
                      id="logo-input"
                      type="file"
                      name="logo"
                      style={styles.hidden}
                      onChange={this.handleFileChange.bind(this)}
                      accept="image/*"
                    />
                  </Button>
                </Tooltip>
                {options.logo_file && (
                  <Chip
                    label={options.logo_file.name}
                    onDelete={this.handleFileRemove.bind(this)}
                  />
                )}
              </div>
              <TextField
                label="Primary Color"
                value={options.primary_color || defaultColor}
                onChange={this.handleChange('primary_color')}
                style={styles.input}
                inputProps={{ style: { color: options.primary_color } }}
                helperText="Format: #aa1144"
                required
              />
              <TextField
                label="Accent Color"
                value={options.accent_color || defaultColor}
                onChange={this.handleChange('accent_color')}
                style={styles.input}
                inputProps={{ style: { color: options.accent_color } }}
                helperText="Format: #aa1144"
                required
              />
              <TextField
                label="Error Color"
                value={options.error_color || defaultColor}
                onChange={this.handleChange('error_color')}
                style={styles.input}
                inputProps={{ style: { color: options.error_color } }}
                helperText="Format: #aa1144"
                required
              />
              <TextField
                label="Heat/Cool Color"
                value={options.heatcool_selection || ''}
                onChange={this.handleChange('heatcool_selection')}
                select
                style={styles.input}
                required>
                <MenuItem value="" />
                <MenuItem value="primary">Primary</MenuItem>
                <MenuItem value="accent">Accent</MenuItem>
              </TextField>
            </React.Fragment>
          ) : (
            <CircularProgress style={styles.center} />
          )}
        </CardContent>
      </Card>
    )
  }
}

Customization.propTypes = {
  id: PropTypes.number
}

export default Customization
