import React from 'react'
import {
  BooleanInput, Create, FormDataConsumer, SimpleForm, TextInput, required
} from 'react-admin'

const PMS_PAYLEASE = 'paylease'
const pmsParser = v => (v ? PMS_PAYLEASE : null)

const CompaniesCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="phone" />
      <TextInput source="website" />
      <TextInput source="billing_address" />
      <TextInput source="shipping_address" />
      <BooleanInput source="pms" label="PayLease PMS" parse={pmsParser} defaultValue={PMS_PAYLEASE} />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.pms &&
          <TextInput source="integration_info.company_id"
            label="Company UUID"
            validate={required()}
            {...rest}
          />
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)

export default CompaniesCreate
