/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, FormControlLabel, TextField, Button } from '@material-ui/core/'
import CircularProgress from '@material-ui/core/CircularProgress'
import Toast from 'react-material-toast'
import http from '../../api/http'
import validateLockCode from '../../utils/validationUtils'
import {
  isIndexAvailable,
  getFirstAvailableIndex,
  lockCodeExists
} from '../../utils/deviceUtils'

const buttonClass = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '20px 10px 0px 10px'
}

const toast = Toast.new({
  place: 'topCenter',
  duration: 5
})

class DoorLockCommands extends Component {
  state = {
    lockCode: '',
    lockCodeError: '',
    showCreateSpinner: false,
    showLockToggleSpinner: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.state !== this.props.data.state) {
      this.setState({
        showLockToggleSpinner: false
      })
    }
    if (lockCodeExists(nextProps.data.lock_codes, this.state.lockCode)) {
      this.setState({
        showCreateSpinner: false
      })
    }
  }

  handleChange = event => {
    const { value, name } = event.target
    this.setState({
      [name]: value,
      lockCodeError: validateLockCode(value)
    })
  }

  onClick = () => {
    const { sendCommand, data } = this.props
    if (!isIndexAvailable(data)) {
      this.setState({
        lockCodeError: 'Delete index 1, 2 or 3 to create code'
      })
      return
    }
    sendCommand('door_lock', data.identifier, {
      [`user_code_${getFirstAvailableIndex(data.lock_codes)}`]: this.state
        .lockCode
    })
    this.setState({
      showCreateSpinner: true
    })
  }

firmwareUpdate = async () => {
  const { data: { id }, title } = this.props
  await http.devices.updateFirmware({ id })
  toast.success(`Firmware Update for ${title} sent`)
}

  onToggleChange = () => {
    const { sendCommandRaw, data } = this.props
    sendCommandRaw({
      name: 'door_lock_basic',
      target: data.identifier,
      action: data.state === 'locked' ? 'unlock' : 'lock'
    })
    this.setState({
      showLockToggleSpinner: true
    })
  }

  render() {
    const { state } = this.props.data
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
          <TextField
            label="Lock Code"
            errorText={this.state.lockCodeError}
            fullWidth
            onChange={this.handleChange}
            name="lockCode"
            value={this.state.lockCode}
            style={{ width: '45%', margin: '1em' }}
          />
          {/* <DatePicker
            label="Expiration"
            container="inline"
            minDate={new Date()}
            fullWidth={true}
            style={{ width: "45%", margin: "1em" }}
          /> */}
        </div>
        <div style={buttonClass}>
          {this.state.showCreateSpinner ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '10px'
              }}>
              <CircularProgress size={30} />
            </div>
          ) : (
            <Button
              onClick={this.onClick}
              fullWidth
              color="primary"
              variant="contained">
              Create
            </Button>
          )}
        </div>
        <div style={buttonClass}>
          <Button
            onClick={this.firmwareUpdate}
            fullWidth
            color="primary"
            variant="contained">
            Update Firmware
          </Button>
        </div>
        <div style={{ padding: '20px 0px 10px 0px' }}>
          {this.state.showLockToggleSpinner ? (
            <CircularProgress size={30} />
          ) : (
            <FormControlLabel
              label={state === 'locked' ? 'Unlock' : 'Lock'}
              control={
                <Switch
                  checked={state === 'locked'}
                  onChange={this.onToggleChange}
                />
              }
            />
          )}
        </div>
      </div>
    )
  }
}

DoorLockCommands.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    identifier: PropTypes.string,
    lock_codes: PropTypes.array
  }),
  sendCommandRaw: PropTypes.func,
  sendCommand: PropTypes.func,
  title: PropTypes.string
}

export default DoorLockCommands
