import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  ChipField
} from 'react-admin'
import ArrayObjectField from '../fields/array-object-field'
import UserFilter from './userFilter'

const UserList = props => (
  <List {...props} perPage={10} filters={<UserFilter />}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone_number" />
      <ChipField source="role" />
      <ChipField source="account_status" />
      <BooleanField label="Resident App" source="has_used_resident_app" />
      <ArrayObjectField
        source="units"
        reference="units"
        label="Unit"
        linkType="show"
      />
      <EditButton />
    </Datagrid>
  </List>
)

export default UserList
