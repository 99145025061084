/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  EditButton,
  ListButton,
  RefreshButton,
  Show,
  TextField,
  BooleanField,
  ReferenceField,
  DateField,
  FunctionField
} from 'react-admin'
import { Button, CardActions } from '@material-ui/core'

import { jsx } from '@emotion/core'
import GridShowLayout from '../ra-custom/gridShowLayout'
import TransparentContainer from '../common/transparentContainer'

import { linkToRecord } from '../utils/linkUtils'
import ObjectField from '../fields/json-field.js'
import DocumentField from './document-field'
import Users from '../users/partials/userList'
import PmsSyncButton from './buttons/pmsSyncButton'
import CreateGroupSelectorsButton from './buttons/createGroupSelectorsButton'
import Customization from './PropertyCustomization'
import AuditSendbirdChannelsButton from './buttons/auditSendbirdChannelsButton'

const integrationTypes = {
  'YavoCredentials': 'Yardi',
  'ResmanCredentials': 'ResMan',
  'MriCredentials': 'MRI'
}

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  justifyContent: 'flex-end'
}

const PropertyShowActions = ({ basePath, data = {} }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <Button
      style={{ overflow: 'inherit' }}
      component={Link}
      to={`${linkToRecord(basePath, data.id)}/settings`}
      color="primary"
      variant="contained">
      Settings
    </Button>
    <ListButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
)

const PropertiesShow = props => (
  <React.Fragment>
    <Show actions={<PropertyShowActions />} {...props}>
      <GridShowLayout cols={2}>
        <TransparentContainer className="colspan-3">
          <PmsSyncButton type="users" />
          <PmsSyncButton type="units" />
          <CreateGroupSelectorsButton />
          <AuditSendbirdChannelsButton />
        </TransparentContainer>
        <TextField source="id" />
        <GridShowLayout cols={2} padding={0}>
          <TextField source="name" />
          <TextField source="integration_info.company_pms_id" label="PM ID" />
        </GridShowLayout>
        <ReferenceField
          label="Company"
          source="company_id"
          reference="companies"
          linkType="show">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="enabled" />
        <TextField source="phone_number" />
        <TextField source="emergency_phone_number" />
        <TextField source="address" />
        <TextField source="office_address_line_1" />
        <TextField source="office_address_line_2" />
        <TextField source="office_address_city" />
        <TextField source="office_address_state" />
        <TextField source="office_address_zip" />
        <TextField source="office_hours" />
        <DateField source="launch_date" options={{ timeZone: 'UTC' }} />
        <TextField source="pms" label="Property Mgmt Software (PMS)" />
        <FunctionField label="Integration Type" render={({ integration_type }) => integrationTypes[integration_type]} />
        <DateField source="updated_at" label="Last Updated" showTime />
        <ObjectField
          source="integration_info"
          css={{ margin: '100px' }}
          label="PMS Integration Info"
          className="colspan-3"
        />
        <DocumentField source="documents" className="colspan-2" />
      </GridShowLayout>
    </Show>
    <Customization {...props} />
    <Users
      {...props}
      title="Staff"
      roles="property_admin,company_admin,property_staff,setup"
    />
  </React.Fragment>
)

PropertyShowActions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number
  }),
  basePath: PropTypes.string
}

export default PropertiesShow
