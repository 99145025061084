import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Dropzone from 'react-dropzone'
import http from '../api/http'
import {DialogContent, Typography} from '@material-ui/core'

const accept =
  'application/pdf, image/jpeg, image/png, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
const maxSize = 10485760

const defaultStyle = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    textAlign: 'center',
    color: '#999'
  },
  acceptStyle: {
    background: '#20a592'
  },
  preview: {
    float: 'left'
  }
}

class DocumentUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeDialog: '',
      name: props.name || '',
      isNew: !props.name
    }

    this.onChange = this.onChange.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.onUpload = this.onUpload.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
  }

  onChange(e) {
    this.setState({ name: e.target.value })
  }

  onDrop([file]) {
    const name = this.state.name || file.name
    this.setState({ file, name })
  }

  async onUpload() {
    const { property_id } = this.props
    const { name, file } = this.state
    const property = await http.properties.uploadDocument({
      property_id,
      name,
      file
    })
    this.setState({ name: '' })
    this.props.onUpload(property)
  }

  deleteDocument = async () => {
    const { name, property_id } = this.props
    http.properties.deleteDocument({ name, property_id })
    .then(response => {
      this.props.onUpload(response.body)
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log(error)
    })
  }

  render() {
    const { name, isNew, file, activeDialog } = this.state
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'Baseline',
          paddingBottom: '7px'
        }}>
        <TextField
          style={{ margin: '1em' }}
          label="Document Name"
          onChange={e => this.onChange(e)}
          value={name || name}
          disabled={!isNew}
        />
        <Dropzone
          onDrop={this.onDrop}
          accept={accept}
          maxSize={maxSize}
          minSize={100}
          multiple={false}
          style={defaultStyle.dropZone}
          acceptStyle={defaultStyle.acceptStyle}>
          {({ getRootProps, getInputProps }) => (
            <Button variant="contained" disabled={!isNew} {...getRootProps()}>
            <input {...getInputProps()} />
            Choose File
            </Button>
          )}
        </Dropzone>
        <div style={{ padding: '1em' }}>
          {isNew && (
            <Button
              color="primary"
              variant="contained"
              disabled={!file || !name}
              onClick={this.onUpload}
            >
              Upload
            </Button>
          )}
          {!isNew && (
            <Button color="red"
              variant="contained"
              onClick={() => {this.setState({ activeDialog: 'delete' })}}
            >
              Delete
            </Button>
          )}
        </div>
        <Dialog open={activeDialog === 'delete'}>
          <DialogContent>
            <Typography>
              Are you sure you want to delete the file from the database and cloud storage?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color='primary' variant='contained' onClick={this.deleteDocument}>Yes, Delete</Button>
            <Button color='warning' variant='contained' onClick={() => this.setState({ activeDialog: '' })}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}


DocumentUpload.propTypes = {
  name: PropTypes.string,
  property_id: PropTypes.number,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func
}

export default class PropertyDocuments extends Component {
  constructor(props) {
    super(props)
    this.state = props.record
  }

  onUpdate = property => {
    this.setState(property)
  }

  render() {
    const { id, name, documents = {} } = this.state
    return (
      <Card>
        <CardHeader
          title={`Property Documents For ${name}`}
          titleStyle={{ fontSize: '24px' }}
        />
        <List>
          {Object.entries(documents).map(([k]) => (
            <DocumentUpload
              key={`property-doc-${k}`}
              name={k}
              property_id={id}
              onUpload={this.onUpdate}
            />
          ))}
          <DocumentUpload
            key="new-property-doc"
            property_id={id}
            onUpload={this.onUpdate}
          />
        </List>
      </Card>
    )
  }
}


PropertyDocuments.propTypes = {
  record: PropTypes.object
}
