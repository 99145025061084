import React from 'react'
import { Admin, Resource } from 'react-admin'
import { ThemeProvider } from '@material-ui/styles'
import Dashboard from './dashboard'
import authClient from './authClient'
import zegoRestClient from './restClient'
import Menu from './routing/Menu'
import customRoutes from './routing/customRoutes'
import {
  UnitsList, UnitsShow, UnitsCreate, UnitsEdit
} from './units'
import {
  UsersList, UserShow, UserCreate, UserEdit
} from './users'
import { DeviceList, DeviceShow } from './devices'
import {
  CompaniesList,
  CompaniesShow,
  CompaniesCreate,
  CompaniesEdit
} from './companies'
import {
  PropertiesList,
  PropertiesShow,
  PropertiesCreate,
  PropertiesEdit
} from './properties'
import {
  EmailTemplateList,
  EmailTemplateShow,
  EmailTemplateCreate,
  EmailTemplateEdit
} from './email_templates'
import {
  SMSTemplateList,
  SMSTemplateShow,
  SMSTemplateCreate,
  SMSTemplateEdit
} from './sms_templates'
import { EventList, EventShow } from './events'
import {
  CardList, CardShow, CardCreate, CardEdit
} from './cards'
import {
  SettingList,
  SettingShow,
  SettingCreate,
  SettingEdit
} from './settings'
import zegoTheme from './zego-theme'

window.api_url = process.env.REACT_APP_API_URL || 'https://api-stage.zego.io/api'

const App = () => (
    <ThemeProvider theme={zegoTheme}>
      <Admin
        title="Zego Support UI"
        theme={zegoTheme}
        menu={Menu}
        customRoutes={customRoutes}
        dashboard={Dashboard}
        dataProvider={zegoRestClient}
        authProvider={authClient}
      >
        <Resource
          name="units"
          list={UnitsList}
          show={UnitsShow}
          create={UnitsCreate}
          edit={UnitsEdit}
        />
        <Resource
          name="companies"
          list={CompaniesList}
          show={CompaniesShow}
          create={CompaniesCreate}
          edit={CompaniesEdit}
        />
        <Resource
          name="properties"
          list={PropertiesList}
          show={PropertiesShow}
          create={PropertiesCreate}
          edit={PropertiesEdit}
        />
        <Resource
          name="users"
          list={UsersList}
          show={UserShow}
          create={UserCreate}
          edit={UserEdit}
        />
        <Resource name="devices" list={DeviceList} show={DeviceShow} />
        <Resource
          name="email_templates"
          list={EmailTemplateList}
          show={EmailTemplateShow}
          create={EmailTemplateCreate}
          edit={EmailTemplateEdit}
        />
        <Resource
          name="text_templates"
          list={SMSTemplateList}
          show={SMSTemplateShow}
          create={SMSTemplateCreate}
          edit={SMSTemplateEdit}
        />
        <Resource name="events" list={EventList} show={EventShow} />
        <Resource
          name="cards"
          list={CardList}
          show={CardShow}
          create={CardCreate}
          edit={CardEdit}
          
        />
        <Resource
          name="settings"
          list={SettingList}
          show={SettingShow}
          create={SettingCreate}
          edit={SettingEdit}
        />
      </Admin>
    </ThemeProvider>
  )

export default App
