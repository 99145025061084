import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, FormControlLabel, CircularProgress } from '@material-ui/core'

import http from '../api/http'

class ToggleButton extends Component {
  constructor(props) {
    super(props)
    this.state = { isToggled: this.props.checked, isLoading: false }

    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle = async () => {
    const data = this.props
    const { resource } = data
    const { id } = data.record
    const payload = { enable: !this.state.isToggled }
    try {
      this.setState(prevState => ({ isLoading: !prevState.isLoading }))
      const response = await http.enableResource(resource, id, payload)

      if (response.statusCode === 200) {
        this.setState(prevState => ({
          isToggled: !prevState.isToggled,
          isLoading: !prevState.isLoading
        }))
      }
    } catch (e) {
      this.setState(prevState => ({ isLoading: !prevState.isLoading }))
      alert(e)
    }
  }

  render() {
    const loading = this.state.isLoading
    return (
      <div>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormControlLabel
            label="Enabled"
            control={
              <Switch
                onChange={this.handleToggle}
                checked={this.state.isToggled}
              />
            }
          />
        )}
      </div>
    )
  }
}

ToggleButton.propTypes = {
  resource: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.number
  }),
  checked: PropTypes.bool
}

export default ToggleButton
