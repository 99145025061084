import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField
} from 'react-admin'
import DeviceFilter from './deviceFilter'

const DeviceList = props => (
    <List
      {...props}
      perPage={20}
      filter={{ type: 'gateway' }}
      filters={<DeviceFilter />}
    >
      <Datagrid>
        <ReferenceField
          label="Device Id"
          source="id"
          reference="devices"
          linkType="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="last_state.status" />
        <TextField source="identifier" />
        <TextField source="unit_name" />
        <DateField source="inserted_at" />
        <TextField label="Visible Hub ID" source="visible_identifier"/>
      </Datagrid>
    </List>
  )

export default DeviceList
