import React from 'react'
import {
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'

const UnitsFilter = props => (
    <Filter {...props}>
      <TextInput label="Search Units by name" source="name" alwaysOn />
      <ReferenceInput
        label="Property Name"
        source="property_id"
        reference="properties"
        sort={{ field: 'name', order: 'DESC' }}
        perPage={100}
        filterToQuery={searchText => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <SelectInput
        label="Status"
        source="status"
        choices={[
          { id: 'healthy', name: 'Healthy' },
          { id: 'unhealthy', name: 'Unhealthy' },
          { id: 'offline', name: 'Offline' }
        ]}
      />
      <TextInput label="Type" source="type" />
    </Filter>
  )

export default UnitsFilter
