import React from 'react'
import {
  SimpleForm,
  TextInput,
  required
} from 'react-admin'
import Edit from '../ra-custom/edit'

const SettingEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="description" multiline fullWidth validate={required()} />
      </SimpleForm>
    </Edit>
  )

export default SettingEdit
