import React from 'react'
import PropTypes from 'prop-types'
import {
  SimpleShowLayout,
  TextField,
  RichTextField,
  DeleteButton
} from 'react-admin'
import Show from '../ra-custom/show'

const EmailTemplateTitle = ({ record }) => (
  <span>{record ? `${record.name}` : ''}</span>
)

const UserShow = props => (
  <Show title={<EmailTemplateTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="subject" />
      <TextField source="from" />
      <RichTextField source="body" />
      <DeleteButton />
    </SimpleShowLayout>
  </Show>
)

EmailTemplateTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string
  })
}

export default UserShow
