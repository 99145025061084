import React from 'react'
import PropTypes from 'prop-types'
import {
  Show,
  TextField,
  BooleanField,
  ImageField,
  EditButton,
  ListButton,
  DeleteButton,
  UrlField
} from 'react-admin'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import swal from 'sweetalert'
import GridShowLayout from '../ra-custom/gridShowLayout'

import http from '../api/http'

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  justifyContent: 'flex-end'
}

const assignCard = async card_id => {
  // texts for the user input prompt
  const input_add_remove_card = {
    text: 'Do you want to add or remove this card to a property?',
    buttons: {
      assign: {
        text: 'Add Card',
        value: true
      },
      unassign: {
        text: 'Remove Card',
        value: false
      },
      cancel: 'Cancel'
    }
  }
  const input_property_id = {
    text:
      'What is the ID of the property you want to assign or unassign this card to?',
    content: 'input',
    inputPlaceholder: 'Property ID',
    button: {
      text: 'Continue'
    },
    showCancelButton: true
  }

  let property_id
  let assign_or_remove

  // TRY ask the user if they want to add or remove the card
  try {
    const input = await swal(input_add_remove_card)
    assign_or_remove = input
  } catch (e) {
    return swal(`Unknown error occured. Here's why: ${e.message}`)
  }

  // TRY ask the user for a property_id
  try {
    const input = await swal(input_property_id)
    property_id = parseInt(input, 10)
    if (!property_id) return swal('No property ID entered.')
  } catch (e) {
    return swal(`Unknown error occured. Here's why: ${e.message}`)
  }

  // TRY to see if the property_id exists
  try {
    const response = await http.properties.get({ id: property_id })
    if (response.body.id === property_id) {
      // If the correct property is found, TRY to assign the card_id to the property_id
      try {
        const type = assign_or_remove ? 'assign' : 'unassign'
        const response = await http.cards.toggleForProperty({
          card_id,
          property_id,
          type
        })
        if (response.statusCode === 200) {
          return swal(`Card ${type}ed!`)
        }
      } catch (e) {
        return swal(`Failed. Here's why: ${e.message}`)
      }
    }
  } catch (e) {
    return swal(`Unable to find that property. Here's why: ${e.message}`)
  }
}

const CardShowActions = ({ basePath, data }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    <DeleteButton basePath={basePath} record={data} />
    <Button backgroundColor="#a5a5a554" onClick={() => assignCard(data.id)}>
      Add/Remove From Property
    </Button>
  </CardActions>
)

const CardShow = props => (
  <Show actions={<CardShowActions />} {...props}>
    <GridShowLayout>
      <ImageField
        source="data.image"
        label="Image"
        hideOnEmpty
        className="colspan-3"
      />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="data.title" label="Title" />
      <TextField source="data.category" label="Category" />
      <TextField source="level" />
      <BooleanField source="global" />
      <TextField source="data.discount_description" label="Discount" />
      <UrlField source="data.redirect_url" label="Link" />
      <TextField
        source="data.description"
        label="Description"
        className="colspan-3"
      />
    </GridShowLayout>
  </Show>
)

CardShowActions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string
  }),
  basePath: PropTypes.string
}

export default CardShow
