import React from 'react'
import PropTypes from 'prop-types'
import { EditButton, ListButton, DeleteButton, Show } from 'react-admin'
import CardActions from '@material-ui/core/CardActions'

const cardActionStyle = {
  zIndex: 2,
  justifyContent: 'flex-end'
}

const Actions = ({ basePath, data, resource, hideEdit, hideDelete }) => (
  <CardActions style={cardActionStyle}>
    {!hideEdit && <EditButton basePath={basePath} record={data} />}
    <ListButton basePath={basePath} />
    {!hideDelete && (
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    )}
  </CardActions>
)

const CustomShow = ({ hideEdit = false, hideDelete = false, ...rest }) => (
  <Show
    actions={<Actions hideDelete={hideDelete} hideEdit={hideEdit} />}
    {...rest}
  />
)

Actions.propTypes = {
  data: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool
}

CustomShow.propTypes = {
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool
}

export default CustomShow
