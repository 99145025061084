import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  FormDataConsumer
} from 'react-admin'

const parseId = v => [parseInt(v, 10)]

const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="role"
        validate={required()}
        choices={[
          { id: 'resident', name: 'Resident' },
          { id: 'setup', name: 'Setup' },
          { id: 'property_staff', name: 'Property Staff' },
          { id: 'property_admin', name: 'Property Admin' },
          { id: 'company_admin', name: 'Company Admin' },
          { id: 'super_admin', name: 'Super Admin' }
        ]}
      />
      <FormDataConsumer>
        {({ formData }) => formData.role &&
          formData.role === 'resident' && (
            <React.Fragment>
              <TextInput source="email" label="Email" validate={required()} />
              <TextInput source="login" label="Login" validate={required()} />
              <TextInput
                source="units"
                parse={parseId}
                label="Unit ID"
                validate={required()}
              />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => formData.role &&
          formData.role === 'property_staff' && (
            <React.Fragment>
              <TextInput source="email" label="Email" validate={required()} />
              <TextInput source="login" label="Login" validate={required()} />
              <TextInput
                source="properties"
                parse={parseId}
                label="Property ID"
                validate={required()}
              />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => formData.role &&
          formData.role === 'setup' && (
            <React.Fragment>
              <TextInput source="email" label="Email" validate={required()} />
              <TextInput
                source="properties"
                parse={parseId}
                label="Property ID"
                validate={required()}
              />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => formData.role &&
          formData.role === 'property_admin' && (
            <React.Fragment>
              <TextInput source="email" label="Email" validate={required()} />
              <TextInput source="login" label="Login" validate={required()} />
              <TextInput
                source="properties"
                parse={parseId}
                label="Property ID"
                validate={required()}
              />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => formData.role &&
          formData.role === 'company_admin' && (
            <React.Fragment>
              <TextInput source="email" label="Email" validate={required()} />
              <TextInput source="login" label="Login" validate={required()} />
              <TextInput
                source="companies"
                parse={parseId}
                label="Company ID"
                validate={required()}
              />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer dependsOn="role" value="super_admin">
        {({ formData }) => formData.role &&
          formData.role === 'super_admin' && (
            <React.Fragment>
              <TextInput source="email" label="Email" validate={required()} />
              <TextInput source="login" label="Login" validate={required()} />
            </React.Fragment>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)

export default UserCreate
