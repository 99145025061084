import React from 'react'
import PropTypes from 'prop-types'
import { TextField, ReferenceField } from 'react-admin'
import lodashGet from 'lodash.get'

const ArrayObjectField = ({
  source,
  reference,
  label,
  record = {},
  hideOnEmpty,
  ...props
}) => {
  const result = lodashGet(record, source)
  if (!Array.isArray(result) || result.length === 0) {
    if (hideOnEmpty) {
      return null
    }
    return <span>NA</span>
  }

  const [{ id, name }] = result

  return (
    <ReferenceField
      {...props}
      source="id"
      reference={reference}
      label={label}
      linkType="show"
      record={{ id, name }}>
      <TextField source="name" />
    </ReferenceField>
  )
}

ArrayObjectField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  reference: PropTypes.string,
  label: PropTypes.string,
  hideOnEmpty: PropTypes.bool
}

export default ArrayObjectField
