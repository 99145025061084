/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

class ThermostatCommands extends Component {
  state = {
    heatSetpoint: this.props.data.occ_heat_sp,
    coolSetpoint: this.props.data.occ_cool_sp,
    mode: this.props.data.system_mode || 'off'
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      heatSetpoint: nextProps.data.occ_heat_sp,
      coolSetpoint: nextProps.data.occ_cool_sp,
      mode: nextProps.data.system_mode
    })
  }

  onChange = (event, key, payload) => {
    let { name, value } = event.target
    if (payload) {
      name = 'mode'
      value = payload
    }
    this.setState({
      [name]: value
    })
  }

  onClick = type => {
    const { sendCommand, data } = this.props
    sendCommand(
      'thermostat',
      data.identifier,
      type === 'setpoint'
        ? {
            occ_cool_sp: parseInt(this.state.coolSetpoint, 10),
            occ_heat_sp: parseInt(this.state.heatSetpoint, 10)
          }
        : {
            system_mode: this.state.mode
          }
    )
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: '0px 20px 0px 20px'
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
          <TextField
            label="Cool Setpoint"
            type="number"
            value={this.state.coolSetpoint || 0}
            style={{ width: '30%', margin: '1em' }}
            name="coolSetpoint"
            onChange={this.onChange}
          />
          <TextField
            label="Heat Setpoint"
            type="number"
            value={this.state.heatSetpoint || 0}
            style={{ width: '30%', margin: '1em' }}
            name="heatSetpoint"
            onChange={this.onChange}
          />
          <Button
            onClick={() => this.onClick('setpoint')}
            color="primary"
            variant="contained">
            Set
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
          <Select
            value={this.state.mode}
            label="Mode"
            fullWidth
            name="mode"
            onChange={this.onChange}
            style={{ width: '68%', margin: '1em' }}>
            <MenuItem value="cool">Cool</MenuItem>
            <MenuItem value="heat">Heat</MenuItem>
            <MenuItem value="auto">Auto</MenuItem>
            <MenuItem value="off">Off</MenuItem>
          </Select>
          <Button onClick={this.onClick} color="primary" variant="contained">
            Set
          </Button>
        </div>
      </div>
    )
  }
}

ThermostatCommands.propTypes = {
  data: PropTypes.shape({
    occ_heat_sp: PropTypes.number,
    occ_cool_sp: PropTypes.number,
    system_mode: PropTypes.string,
    identifier: PropTypes.string
  }),
  sendCommand: PropTypes.func
}

export default ThermostatCommands
