import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import http from '../../api/http'
import Toast from 'react-material-toast'

const toast = Toast.new({
  place: 'topLeft',
  duration: 5
})

const SyncCompanyButton = ({ id }) => {

  const onClick = async () => {
    // eslint-disable-next-line no-unused-vars
    const response = await http.companies.syncPms(id)
    toast.success('Success! Sync with PMS initated')
  }

  return (
    <Button onClick={onClick} variant="contained" color="primary">
      Sync With PMS
    </Button>
  )
}

SyncCompanyButton.propTypes = {
  id: PropTypes.string
}

export default SyncCompanyButton
