import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required
} from 'react-admin'
import Edit from '../ra-custom/edit'

const textFieldStyle = {
  flex: '1 1 33%'
}

const UnitsEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ReferenceInput
          label="Properties"
          source="property.id"
          reference="properties"
          style={textFieldStyle}
          allowEmpty
          validate={required()}
          perPage={100}
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <SelectInput
          source="type"
          choices={[
            { id: 'residential', name: 'residential' },
            { id: 'model', name: 'model' },
            { id: 'common', name: 'common' }
          ]}
        />
        <BooleanInput
          label="Enable Smart Cellular Warning?"
          source="cell_notification"
        />
      </SimpleForm>
    </Edit>
  )

export default UnitsEdit
