import React from 'react'
import { Filter, SelectInput } from 'react-admin'

const EventFilter = props => (
    <Filter {...props}>
      <SelectInput
        optionText="name"
        choices={[]}
        source="type"
        defaultValue={null}
      />
    </Filter>
  )

export default EventFilter
