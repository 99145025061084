import { compose } from 'lodash/fp'
import * as moment from 'moment'

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

// eslint-disable-next-line implicit-arrow-linebreak
const replaceUnderscoreWithSpace = string => string.replace(/_/g, ' ')

const convertTimezone = str => {
  if (str.startsWith('20') && str.length > 14) {
    const m = new moment(str)
    return moment
      .parseZone(m)
      .local()
      .format('ddd, MM/D YYYY, h:mm:ss a')
  }
  return str
}

export const toDisplayName = compose(
  convertTimezone,
  capitalizeFirstLetter,
  replaceUnderscoreWithSpace
)

// convert to hex value - 1 and then back to string
export const convertHubIDToScoutHubID = macAddress => (parseInt(macAddress, 16) - 1).toString(16)
