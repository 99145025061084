/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import http from '../../api/http'
import Toast from 'react-material-toast'

const toast = Toast.new({
  place: 'topLeft',
  duration: 5
})

class AuditSendbirdChannelsButton extends Component {
  handleClick = () => {
    const {
      record: { id }
    } = this.props

    http.properties
      .auditSendbirdChannels(id)
      .then(res =>
        // eslint-disable-next-line implicit-arrow-linebreak
        res.ok && toast.success('Auditing sendbird channels...')
      )
      .catch(err => toast.error(err.message))
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        style={{ margin: '1em' }}
        color="primary"
        variant="contained">
        Audit SendBird Channels
      </Button>
    )
  }
}

AuditSendbirdChannelsButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number
  })
}

export default AuditSendbirdChannelsButton
